import { createSlice } from "@reduxjs/toolkit";
import { getTransactions } from "./transactionsActions";

const initialState = {
  loading: false,
  error: null,
  transactions: [],
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransactions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.transactions = payload;
      })
      .addCase(getTransactions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.transactions.loading;
export const selectLoadingError = (state) => state.transactions.error;
export const selectTransactions = (state) => state.transactions.transactions;

export default transactionsSlice.reducer;
