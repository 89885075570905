import './App.css';
import AppRoute from "./route/AppRoute";

function App() {
    return (
        <div className="App">
            <AppRoute/>
        </div>
    );
}

export default App;
