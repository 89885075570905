import styled from "styled-components";
import warehouse from "assets/login/warehouse.png";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 522px;
  gap: 55px;
`;

export const Image = styled.div`
  background-image: url(${warehouse});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  padding-left: 48px;

  h1 {
    color: #fff;
    font-size: 116px;
    font-style: normal;
    font-weight: 840;
    line-height: 116px; /* 100% */
    text-transform: uppercase;
    margin-top: -23px;
  }
`;

export const ColumnContainer = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
`;
