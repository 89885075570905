import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 102px;
  font-weight: 840;
  text-transform: uppercase;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  /* gap: 196px; */
  width: 100%;
  padding: 60px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const TableWrapper = styled.div`
  @media (max-width: 768px) {
  }
`;
