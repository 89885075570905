import { Title, Image, Container } from "./Map.styles";
import OnMap from "assets/on-map.png";

const Map = () => {
  return (
    <Container>
      <Title>
        we are on the <span>map</span>
      </Title>
      <Image src={OnMap} alt="map" />
    </Container>
  );
};

export default Map;
