import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsLoginPage } from "features/auth/authSlice";
import { setNewPassword } from "features/auth/authActions";
import Input from "components/input";
import Button from "components/button";
import { Row, Image, Text, MiniCol } from "./New.styles";

const New = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    value: "",
    isValid: true,
    errorMessage: "",
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    isValid: true,
    errorMessage: "",
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const email = searchParams.get("email");

  const handleChangePassword = () => {
    const isFormValid = validateInputs();

    if (isFormValid) {
      dispatch(setNewPassword({ code: key, email, password: password.value }));
    }
  };

  const validateInputs = () => {
    const isPasswordValid =
      password.value.length >= 8 &&
      /[A-Z]/.test(password.value) &&
      /[a-z]/.test(password.value) &&
      /\d/.test(password.value) &&
      /[!@?#$%^&*-]/.test(password.value) &&
      password.value === confirmPassword.value;

    setPassword({
      ...password,
      isValid: isPasswordValid,
      errorMessage: isPasswordValid
        ? ""
        : "Password must meet the specified criteria and match the confirm password",
    });

    setConfirmPassword({
      ...confirmPassword,
      isValid: isPasswordValid,
      errorMessage: isPasswordValid
        ? ""
        : "Password must meet the specified criteria and match the confirm password",
    });

    return isPasswordValid;
  };

  return (
    <Row>
      <Image>
        <Text>CHANGE</Text>
        <Text>PASSWORD</Text>
      </Image>
      <MiniCol>
        <Input
          label="Create a new password"
          type="password"
          value={password.value}
          autoComplete="off"
          onChange={(e) => setPassword({ ...password, value: e.target.value })}
          required
          $isValid={password.isValid}
          errorMessage={password.errorMessage}
        />
        <Input
          label="Reenter a new password"
          type="password"
          autoComplete="off"
          value={confirmPassword.value}
          onChange={(e) => setConfirmPassword({ ...confirmPassword, value: e.target.value })}
          required
          $isValid={confirmPassword.isValid}
          errorMessage={confirmPassword.errorMessage}
        />
        <Button onClick={handleChangePassword} width={"100%"}>
          Change password
        </Button>
      </MiniCol>
    </Row>
  );
};

export default New;
