import styled from "styled-components";

export const buttonStyles = ($width) => `
  display: flex;
  width: ${$width}px;
  padding: 12px 27px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 16px;
  border: none;

  color: #FFF;
  font-family: Lexend;
  font-size: 26px;
  font-style: normal;
  line-height: 21px; /* 80.769% */
  text-transform: uppercase;
`;

export const SubmitButton = styled.button`
  ${({ $width }) => buttonStyles($width)}
  background: #58B5E7;
  font-weight: 600;

  &:active {
    background: #f6f3f3;
  }
`;

export const CancelButton = styled.button`
  ${({ $width }) => buttonStyles($width)}
  background: #CED4D7;
  font-weight: 500;

  &:active {
    background: gray;
  }
`;

export const GhostButton = styled.button`
  ${({ $width }) => `
    display: flex;
    width: ${$width}px;
    padding: 12px 27px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 16px;
    border: 2px solid #D9DDDE;
    color: #D9DCDE;
    font-family: Lexend;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    background: white;
  `}

  &:active {
    background: transparent;
  }
`;
