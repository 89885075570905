import { useState } from "react";
import Tab from "../components/tab";
import OrdersTable from "./ordersTable";
import Addresses from "./addresses";
import { Container } from "./Orders.styles";

const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["MY ORDERS", "MY ADDRESSES"];

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Container>
      <Tab tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange}>
        {activeTab === 0 ? <OrdersTable /> : <Addresses />}
      </Tab>
    </Container>
  );
};

export default Orders;
