import styled from "styled-components";

export const MyFieldItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 21px;

  width: 100%;
  color: #262757;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 116.667% */
`;

export const MyFieldItemRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  /* justify-content: space-around; */
`;

export const MyFieldItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ItemLeft = styled.div`
  width: 125px;
`;

export const ItemRight = styled.div`
  min-width: 166px;
`;

export const ItemIcon = styled.img`
  cursor: pointer;
  width: 24px;
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;
