import styled from "styled-components";
import { theme } from "styles/Theme";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 150px 0;
`;

export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 102px;
  font-weight: 840;
  text-transform: uppercase;
`;

export const Description = styled.p`
  color: ${theme.colors.primary};
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 24px;
  font-style: normal;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 50%;
`;

export const Quote = styled.h4`
  color: ${theme.colors.primary};
  font-family: Lexend, sans-serif;
  font-size: 28px;
  font-weight: 500;
`;

export const Image = styled.img`
  width: 600px;

  @media (max-width: 768px) {
    width: 400px;
  }
`;
