import React from "react";
import {
  TableContainer,
  HeaderRow,
  HeaderCell,
  Body,
  DataRow,
  DataCell,
} from "./Table.styles";

const Table = ({ columns, data }) => {
  return (
    <TableContainer>
      <HeaderRow>
        {columns?.map((column, index) => (
          <HeaderCell key={index}>{column.header}</HeaderCell>
        ))}
      </HeaderRow>
      <Body>
        {data?.map((row, rowIndex) => (
          <DataRow key={rowIndex}>
            {columns?.map((column, columnIndex) => (
              <DataCell key={columnIndex}>
                {column.render ? column.render(row[column.key]) : row[column.key]}
              </DataCell>
            ))}
          </DataRow>
        ))}
      </Body>
    </TableContainer>
  );
};

export default Table;
