import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

export const getRates = createAsyncThunk("our/rates", async (_, { rejectWithValue }) => {
  try {
    const { data } = await ApiClient.get("/v1/rates");
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
