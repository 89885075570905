import {
  TabWrapper,
  TabItem,
  TabHeaderWrapper,
  Content,
  TabActions,
  RingContainer,
  ActionsRow,
  ActionsCol,
  ActionsLine,
} from "./Tab.styles";
import { openCalculatorModal } from "features/calculator/calculatorSlice";
import Calculator from "pages/calculator";
import ButtonPersonal from "../buttonPersonal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import calc from "./calc.png";
import ring from "./ring.png";

const Tab = ({ tabs, activeTab, onTabChange, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenCalc = () => {
    dispatch(openCalculatorModal());
  };

  const handleNavigateAddPercel = () => {
    navigate("/add-orders");
  };

  return (
    <>
      <TabWrapper>
        <ActionsCol>
          <ActionsLine />
          <ActionsRow>
            {tabs?.map((tab, index) => (
              <TabItem
                key={index}
                $isActive={activeTab === index}
                onClick={() => onTabChange(index)}
              >
                <TabHeaderWrapper $isActive={activeTab === index}>{tab}</TabHeaderWrapper>
              </TabItem>
            ))}
          </ActionsRow>
        </ActionsCol>

        <TabActions>
          <ButtonPersonal type="ghost" onClick={handleOpenCalc}>
            <img src={calc} alt="calc" />
            Calculator
          </ButtonPersonal>

          <Calculator />

          <RingContainer>
            <img alt="ring" src={ring} />
          </RingContainer>

          <ButtonPersonal type="submit" onClick={handleNavigateAddPercel}>
            + ADD PARCEL
          </ButtonPersonal>
        </TabActions>
      </TabWrapper>
      <Content>{children}</Content>
    </>
  );
};

export default Tab;
