import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import faqReducer from "features/faq/faqSlice";
import howItWorksReducer from "features/howItWorks/howItWorksSlice";
import whyHaystreamReducer from "features/whyHaystream/whyHaystreamSlice";
import cooperationReducer from "features/cooperation/cooperationSlice";
import navigationReducer from "features/navigation/navigationSlice";
import ratesReducer from "features/rates/ratesSlice";
import calculatorReducer from "features/calculator/calculatorSlice";
import addressesReducer from "features/addresses/addressesSlice";
import contactReducer from "features/contact/contactSlice";
import ordersReducer from "features/orders/ordersSlice";
import transactionsReducer from "features/transactions/transactionsSlice";
import recipientsReducer from "features/recipients/recipientsSlice";
import instructionsReducer from "features/instructions/instructionsSlice";
import photoReducer from "pages/personal/components/upload/uploadSlice";
import transportationReducer from "features/transportation/transportationSlice";

const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    // immutableCheck: false,
    // serializableCheck: false,
  });

export const store = configureStore({
  reducer: {
    auth: authReducer,
    faq: faqReducer,
    howItWorks: howItWorksReducer,
    whyHaystream: whyHaystreamReducer,
    cooperation: cooperationReducer,
    navigation: navigationReducer,
    rates: ratesReducer,
    calculator: calculatorReducer,
    addresses: addressesReducer,
    contact: contactReducer,
    orders: ordersReducer,
    transactions: transactionsReducer,
    recipients: recipientsReducer,
    instructions: instructionsReducer,
    photo: photoReducer,
    transportation: transportationReducer,
  },
  middleware,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware),

  devTools: true, // Enable the Redux DevTools extension.
});
