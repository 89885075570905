import { useDispatch, useSelector } from "react-redux";
import { selectFaq } from "features/faq/faqSlice";
import { getFaq } from "features/faq/faqActions";
import Accordion from "components/accordion";
import { Container, Title, Questions, AccordionWrapper } from "./Faq.styles";
import { useEffect } from "react";

const Faq = () => {
  const faqData = useSelector(selectFaq);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaq());
  }, [dispatch]);

  return (
    <Container>
      <Title>
        Frequently asked <Questions>questions</Questions>
      </Title>
      <AccordionWrapper>
        <Accordion data={faqData} />
      </AccordionWrapper>
    </Container>
  );
};

export default Faq;
