import { useNavigate } from "react-router-dom";
import { selectHomeWhyHaystream } from "features/whyHaystream/whyHaystreamSlice";
import Delivery from "./delivery";
import HowItWorks from "./howItWorks";
import WhyHaystream from "../whyHaystream";
import OurRates from "../ourRates";
import box from "./../../assets/box.png";
import Cooperation from "../cooperation";
import Faq from "../faq";
import {
  Box,
  LearnMoreBtn,
  MainText,
  OuterText,
  Wrapper,
  Container,
  Padding,
} from "./Home.styles";
import { useSelector } from "react-redux";

const Home = () => {
  const navigate = useNavigate();

  const whyHaystreamData = useSelector(selectHomeWhyHaystream);

  const handleClickLearnMore = () => {
    navigate("/services");
  };

  return (
    <Container>
      <Padding>
        <Wrapper>
          <MainText>ACCESS TO GLOBAL</MainText>
          <Box src={box} alt={box} />
          <OuterText>MARKETPLACE</OuterText>
          <LearnMoreBtn type="secondary" variant="outlined" onClick={handleClickLearnMore}>
            Learn more
          </LearnMoreBtn>
        </Wrapper>
      </Padding>
      <Padding>
        <Delivery />
      </Padding>
      <Padding>
        <HowItWorks />
      </Padding>
      <WhyHaystream data={whyHaystreamData} />
      <Padding>
        <OurRates />
      </Padding>
      <Cooperation />
      <Padding>
        <Faq />
      </Padding>
    </Container>
  );
};

export default Home;
