import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCooperation } from "features/cooperation/cooperationSlice";
import { getCooperationLogos } from "features/cooperation/cooperationActions";
import { Container, Title, Logo, LogosWrapper } from "./Cooperation.styles";

const Cooperation = () => {
  const data = useSelector(selectCooperation);

  const logos = data?.logos;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCooperationLogos());
  }, [dispatch]);

  return (
    <Container>
      <Title>{data.header}</Title>
      <p>{data.text}</p>
      <LogosWrapper>
        {logos?.map((item) => (
          <Logo key={item.id} alt={item.name} src={item.url} />
        ))}
      </LogosWrapper>
    </Container>
  );
};

export default Cooperation;
