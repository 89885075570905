import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getWhyHaystreamHome,
  getWhyHaystreamAbout,
} from "features/whyHaystream/whyHaystreamActions";
import image from "assets/whyHaystream.png";
import {
  Line,
  Title,
  Image,
  Order,
  Wrapper,
  Container,
  Description,
  DescWrapper,
  SmallerTitle,
  RowContainer,
} from "./WhyHaystream.styles";

const defaultDescriptionItems = [
  // {
  //   order: 1,
  //   title: "Clear shipping costs",
  //   description:
  //     "Providing a transparent breakdown of shipping costs, including potential customs fees, at the time of order placement",
  // },
  // {
  //   order: 2,
  //   title: "Customs Clearance Issues",
  //   description:
  //     "Offering educational resources on navigating customs procedures and providing clear instructions on how to minimise potential issues.",
  // },
  // {
  //   order: 3,
  //   title: "Package Tracking",
  //   description:
  //     "Reliable package tracking system that provides real-time updates and ensures accuracy throughout the shipping process",
  // },
];

const WhyHaystream = ({ data = [] }) => {
  const descriptionItems = [...defaultDescriptionItems, ...data];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWhyHaystreamHome());
    dispatch(getWhyHaystreamAbout());
  }, [dispatch]);

  return (
    <Container>
      <Wrapper>
        <Title>why</Title>
        <Title>haystream ?</Title>
      </Wrapper>
      <Image src={image} alt="whyHaystream" />
      <RowContainer>
        {descriptionItems?.map((item) => (
          <DescriptionItem key={item.order} {...item} />
        ))}
      </RowContainer>
    </Container>
  );
};

const DescriptionItem = ({ order, title, description }) => (
  <DescWrapper>
    <Order>{order}.</Order>
    <Line />
    <SmallerTitle>{title}</SmallerTitle>
    <Description>{description}</Description>
  </DescWrapper>
);

export default WhyHaystream;
