import { useDispatch, useSelector } from "react-redux";
import { openCalculatorModal } from "features/calculator/calculatorSlice";
import { selectRates } from "features/rates/ratesSlice";
import { getRates } from "features/rates/ratesActions";
import Button from "./../../components/button";
import Table from "components/table";
import Calculator from "../calculator/Calculator";
import { RowContainer, Title, TableWrapper } from "./OurRates.styles";
import { useEffect } from "react";

const COLUMNS = [
  { key: "continent", header: " " },
  { key: "economy", header: "Economy" },
  { key: "standard", header: "Standard" },
  { key: "express", header: "Express" },
];

const OurRates = () => {
  const data = useSelector(selectRates);

  const modifiedData = data?.map((item) => ({
    ...item,
    economy: `from $${item.economy}`,
    standard: `from $${item.standard}`,
    express: `from $${item.express}`,
  }));

  const sortedData = modifiedData?.sort((a, b) => a.order - b.order);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRates());
  }, []);

  const handleClickOpenCalcModal = () => {
    dispatch(openCalculatorModal());
  };

  return (
    <>
      <Title>our rates</Title>
      <RowContainer>
        <span>
          <Button
            type="secondary"
            variant="outlined"
            height={91}
            width={230}
            onClick={handleClickOpenCalcModal}
          >
            Calculate
          </Button>
          <Calculator />
        </span>
        <TableWrapper>
          <Table data={sortedData} columns={COLUMNS} bordered />
        </TableWrapper>
      </RowContainer>
    </>
  );
};

export default OurRates;
