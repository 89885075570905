import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

export const getOrders = createAsyncThunk("orders", async (_, { rejectWithValue }) => {
  try {
    const { data } = await ApiClient.get("/v1/orders/");
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const addOrders = createAsyncThunk(
  "/add/orders",
  async (
    { name, shipped_method, tracking_number, total, price, currency, country },
    { rejectWithValue }
  ) => {
    try {
      await ApiClient.post("/v1/orders/", {
        name,
        shipped_method,
        tracking_number,
        total,
        price,
        currency,
        country,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
