import Select from "react-select";
import { capitalizeFirstLetter, generateOptions } from "utils";
import styled from "styled-components";
import { customStyles as defaultCustomStyles } from "./Select.styles";

const CustomSelect = styled(Select)`
  width: 100%;
  height: ${(props) => props.height || "auto"};
`;

const CustomSelectComponent = ({
  value,
  onChange,
  onInputChange,
  options,
  placeholder,
  noOptionsMessage,
  isMulti,
  isClearable,
  customStyles = {},
  height,
  secondaryBorder,
  ...props
}) => {
  const selectOptions = generateOptions(options);

  return (
    <CustomSelect
      value={capitalizeFirstLetter(value)}
      onChange={onChange}
      onInputChange={onInputChange}
      options={selectOptions}
      isMulti={isMulti}
      placeholder={placeholder || ""}
      styles={{ ...defaultCustomStyles, ...customStyles }}
      noOptionsMessage={() => noOptionsMessage}
      isClearabl={isClearable}
      height={height}
      $secondaryBorder={secondaryBorder}
      {...props}
    />
  );
};

export default CustomSelectComponent;
