import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../footer";
import { Container, Main } from "./Layout.styles";

const Layout = ({ children }) => {
  const location = useLocation();

  const isLoginRoute = location.pathname === "/login";

  return (
    <>
      <Navbar marginBottom={isLoginRoute ? 43 : undefined} />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
