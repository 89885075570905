import { useSelector } from "react-redux";
import { selectAboutWhyHaystream } from "features/whyHaystream/whyHaystreamSlice";
import WhyHaystream from "pages/whyHaystream";
import WhatAreWeDoing from "./whatAreWeDoing";
import SendInquiry from "../sendInquiry";
import OurTeam from "./ourTeam";
import Map from "./map";
import { Padding } from "pages/home/Home.styles";

const AboutUs = () => {
  const whyHaystreamData = useSelector(selectAboutWhyHaystream);

  return (
    <>
      <Padding>
        <WhatAreWeDoing />
      </Padding>
      <WhyHaystream data={whyHaystreamData} />
      <Padding>
        <Map />
      </Padding>
      <SendInquiry />
      <Padding>
        <OurTeam />
      </Padding>
    </>
  );
};

export default AboutUs;
