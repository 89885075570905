import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Container = styled.div`
  width: 100%;
  height: 245px;
  padding: 48px 0 60px 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.primary};

  @media (max-width: 768px) {
    padding: 24px;
    height: auto;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 79px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    margin-top: 10px;
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
  color: ${theme.colors.white};
  cursor: pointer;

  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;

  &:hover {
    text-decoration: underline;
  }
`;
