import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWhatDoing } from "features/transportation/transportationActions";
import { selectWhatDoing } from "features/transportation/transportationSlice";
import { Container, Title, Description } from "./WhatAreWeDoing.styles";

const WhatAreWeDoing = () => {
  const data = useSelector(selectWhatDoing);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWhatDoing());
  }, []);

  return (
    <Container>
      <Title>{data?.title}</Title>
      <Description>{data?.description}</Description>
    </Container>
  );
};

export default WhatAreWeDoing;
