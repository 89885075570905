import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPhotoLoading, uploadPhoto } from "./uploadSlice";
import { getUserInfo } from "features/auth/authActions";
import { Container, UploadInput } from "./Upload.styles";

const Upload = () => {
  const dispatch = useDispatch();
  const fileInputRef = React.createRef();
  const isLoading = useSelector(selectPhotoLoading);

  const handleContainerClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      dispatch(uploadPhoto(formData));
    }
  };

  useEffect(() => {
    !isLoading && dispatch(getUserInfo());
  }, [dispatch, isLoading]);

  return (
    <Container onClick={handleContainerClick}>
      +
      <UploadInput type="file" onChange={handleFileChange} ref={fileInputRef} />
    </Container>
  );
};

export default Upload;
