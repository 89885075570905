import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
  width: 100%;
`;

export const LabelWrapper = styled.div`
  border-radius: 16px;
  border: 2px solid #58b5e7;
  background: #fff;
  max-width: 206px;

  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  display: inline-flex;
  height: 54px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  margin-bottom: 26px;
`;

export const PassWrapper = styled.div`
  display: inline-flex;
  height: 54px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  max-width: 300px;

  border-radius: 16px;
  border: 2px solid #58b5e7;
  background: #fff;

  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 87.5% */
`;

export const DeleteAccount = styled.div`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-weight: 500;
  line-height: 21px; /* 87.5% */

  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 16px;
  border: 2px solid #58b5e7;
  background: #fff;
  cursor: pointer;

  width: 30%;

  &:active {
    background: #58b5e7;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
`;

export const LanguageSelectWrapper = styled.div`
  width: 50%;
`;

export const RowEnd = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
  gap: 24px;
`;

export const DeleteConfirmation = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 87.5% */
`;

export const StyledText = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 105% */
`;

export const RowInModal = styled.div`
  display: flex;
  width: 440px;
  gap: 24px;
  align-items: center;
  justify-content: center;
`;

export const ModalCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  padding: 18px 4px;
`;
