import styled from "styled-components";
import { theme } from "styles/Theme";

export const TabWrapper = styled.div`
  display: flex;
  z-index: 99999;
  margin-bottom: -3px;
  border-bottom: 3px solid transparent;
`;

export const TabItem = styled.div`
  z-index: 1;
  cursor: pointer;
  padding: 4px 20px;
  border-radius: 16px 16px 0px 0px;
  border: ${(props) => (props.$isActive ? "3px solid #58B5E7" : "3px solid #DAE4EA")};
  border-bottom: ${(props) => (props.$isActive ? "3px solid #e3f5ff" : "none")} !important;
  color: ${(props) => (props.$isActive ? theme.colors.primary : "#8B8CAA")};
  background-color: ${(props) => props.$isActive && "#e3f5ff"};
  height: 80px;
  min-width: 324px !important;
  /* margin-bottom: -4px; */

  &:first-child {
    border-right: ${(props) => !props.$isActive && "3px solid transparent"};
    border-radius: ${(props) => (!props.$isActive ? "16px 0 0px 0px" : "16px 16px 0px 0px")};
  }
  &:last-child {
    border-radius: ${(props) => (props.$isActive ? "16px 16px 0px 0px" : "0 16px 0px 0px")};
    border-left: ${(props) =>
      !props.$isActive ? "3px solid transparent" : "3px solid #58B5E7"} !important;
  }

  border-bottom: 12px solid #e3f5ff;
  margin-bottom: -3px;
`;

export const TabHeaderWrapper = styled.div`
  height: 73px;
  border-bottom: ${(props) =>
    props.$isActive ? "3px solid #58B5E7" : "3px solid transparent"} !important;

  color: #262757 ${(props) => (props.$isActive ? "#262757" : "#8B8CAA")};
  font-family: Lexend;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  padding: 18px;
  border-radius: 0 16px 16px 16px;
  background-color: #e3f5ff;
  border: 3px solid #58b5e7;
  min-height: 400px;
  display: flex;
  padding: 50px;
  height: 534px;
`;

export const TabActions = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 14px;
  padding-bottom: 20px;
`;

export const RingContainer = styled.div`
  display: flex;
  width: 43px;
  align-items: center;
  justify-content: center;

  border-radius: 16px;
  border: 2px solid #d9dcde;

  cursor: pointer;
`;

export const ActionsRow = styled.div`
  display: flex;
`;

export const ActionsCol = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ActionsLine = styled.hr`
  width: 80%;
  align-self: center;
  height: 3px;
  z-index: 0;
  position: absolute;
  background-color: #dae4ea !important;
  border: none;
`;
