import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { sendInquiry } from "features/sendInquiry/inquiryActions";
import Input from "components/input";
import TextArea from "components/textArea";
import Button from "components/button";
import { RowContainer, ErrorWrapper, MyForm } from "./Form.styles";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  title: yup.string().required("Title is required"),
  your_text: yup.string().required("Text is required"),
});

const Form = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(sendInquiry(data));
  };

  return (
    <MyForm onSubmit={handleSubmit(onSubmit)}>
      <RowContainer>
        <ErrorWrapper>
          <Input
            placeholder="Name"
            {...register("name")}
            type="text"
            $isValid={!errors.name}
          />
          {errors.name && <p>{errors.name.message}</p>}
        </ErrorWrapper>

        <ErrorWrapper>
          <Input
            placeholder="E-mail"
            {...register("email")}
            type="email"
            $isValid={!errors.email}
          />
          {errors.email && <p>{errors.email.message}</p>}
        </ErrorWrapper>
      </RowContainer>

      <ErrorWrapper>
        <Input
          placeholder="Title"
          {...register("title")}
          type="text"
          $isValid={!errors.title}
        />
        {errors.title && <p>{errors.title.message}</p>}
      </ErrorWrapper>

      <ErrorWrapper>
        <TextArea
          placeholder="Your text"
          {...register("your_text")}
          $isValid={!errors.your_text}
        />
        {errors.your_text && <p>{errors.your_text.message}</p>}
      </ErrorWrapper>

      <Button width={177} height={60} type="submit" variant="footerButton">
        Send
      </Button>
    </MyForm>
  );
};

export default Form;
