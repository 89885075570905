import { SubmitButton, CancelButton, GhostButton } from "./ButtonPersonal.styles";

const ButtonPersonal = ({ type, children, onClick, width }) => {
  if (type === "submit") {
    return (
      <SubmitButton type="submit" onClick={onClick} $width={width}>
        {children}
      </SubmitButton>
    );
  } else if (type === "cancel") {
    return (
      <CancelButton type="button" onClick={onClick} $width={width}>
        {children}
      </CancelButton>
    );
  } else if (type === "ghost") {
    return (
      <GhostButton type="button" onClick={onClick} $width={width}>
        {children}
      </GhostButton>
    );
  }

  return null;
};

export default ButtonPersonal;
