import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsCalcModalOpen,
  closeCalculatorModal,
} from "features/calculator/calculatorSlice";
import Input from "components/input";
import Modal from "components/modal";
import Tooltip from "components/tooltip";
import { Select } from "components/select";
import Checkbox from "components/checkbox";
import Slider from "@mui/material-next/Slider";
import planeActive from "./assets/plane-active.png";
import shipActive from "./assets/ship-active.png";
import plane from "./assets/plane.png";
import ship from "./assets/ship.png";
import {
  RowContainer,
  ShipmentType,
  ColumnContainer,
  Title,
  Description,
  Wrapper,
  Duration,
  StyledSpan,
  Result,
} from "./Calculator.styles";

const areaOptions = [
  { value: "usa", label: "USA" },
  { value: "china", label: "China" },
  { value: "germany", label: "Germany" },
  { value: "uk", label: "UK" },
];

const measureOptions = [
  { value: "sm", label: "sm" },
  { value: "in", label: "in" },
  { value: "m", label: "m" },
];

const weightOptions = [
  { value: "kg", label: "kg" },
  { value: "lb", label: "lb" },
];

const Calculator = () => {
  const [activeShipment, setActiveShipment] = useState("plane");
  const [overDimensional, setOverDimensional] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [selectedArea, setSelectedArea] = useState(areaOptions[0]);
  const [selectedMeasure, setSelectedMeasure] = useState(measureOptions[0]);
  const [selectedWeight, setSelectedWeight] = useState(weightOptions[0]);

  const [dimensions, setDimensions] = useState({
    width: "",
    height: "",
    length: "",
  });

  const isOpen = useSelector(selectIsCalcModalOpen);
  const dispatch = useDispatch();

  const handleShipmentClick = (type) => {
    setActiveShipment(type);
  };

  const handleCloseCalcModal = () => {
    dispatch(closeCalculatorModal());
  };

  const CheckboxOnChangeHandler = (isChecked) => {
    setOverDimensional(isChecked);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleWeightValueChange = (event) => {
    const inputValue = event.target.value;
    setSliderValue(inputValue === "" ? "" : Number(inputValue));
  };

  const calculateDimensionMultiplier = (selectedOption) => {
    switch (selectedOption) {
      case "sm":
        return 0.00220462;
      case "m":
        return 100;
      case "in":
        return 2.54;
      default:
        return 1;
    }
  };

  const calculateWeightMultiplier = (selectedOption, defaultOption) => {
    switch (selectedOption) {
      case "kg":
        return 1;
      case "lb":
        return 0.00220462;
      default:
        return 1;
    }
  };

  const dimensionMultiplier = calculateDimensionMultiplier(selectedMeasure.value);
  const weightMultiplier = calculateWeightMultiplier(selectedWeight.value);

  const dimension =
    ((dimensions.width * dimensions.height * dimensions.length) / 5000) * dimensionMultiplier;

  let result = `${0}`;

  !overDimensional &&
    (activeShipment === "plane"
      ? (result = sliderValue * 10 * weightMultiplier)
      : (result = sliderValue * 4 * weightMultiplier));

  overDimensional &&
    (activeShipment === "plane"
      ? (result = sliderValue * 10 * dimension)
      : (result = sliderValue * 4 * dimension));

  const handleDimensionInputChange = (field, value) => {
    setDimensions((prevDimensions) => ({
      ...prevDimensions,
      [field]: value,
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCloseCalcModal} width="647px">
      <ColumnContainer>
        <RowContainer>
          <Wrapper>
            <Title>Delivery calculator</Title>
          </Wrapper>
          <Wrapper $hasBorder={true}>
            <Description>Here you can calculate the approximate cost of delivery</Description>
          </Wrapper>
        </RowContainer>

        <RowContainer>
          <ColumnContainer $gap={13}>
            <ShipmentType
              src={activeShipment === "plane" ? planeActive : plane}
              onClick={() => handleShipmentClick("plane")}
            />
            {activeShipment === "plane" && <Duration>7-10 days</Duration>}
          </ColumnContainer>

          <ColumnContainer $gap={10}>
            <Select
              options={areaOptions}
              value={selectedArea}
              onChange={(value) => setSelectedArea(value)}
            />
            <RowContainer>
              <Input
                secondaryBorder
                height="43px"
                value={sliderValue}
                onChange={handleWeightValueChange}
                type="number"
              />
              <Select
                options={weightOptions}
                value={selectedWeight}
                onChange={(value) => setSelectedWeight(value)}
              />
            </RowContainer>
            <Slider
              value={sliderValue}
              min={0}
              max={70}
              step={0.1}
              onChange={handleSliderChange}
              sx={{
                color: "#58B5E7",
              }}
            />
          </ColumnContainer>

          <ColumnContainer $gap={13}>
            <ShipmentType
              src={activeShipment === "ship" ? shipActive : ship}
              onClick={() => handleShipmentClick("ship")}
            />
            {activeShipment === "ship" && <Duration>60-85 days</Duration>}
          </ColumnContainer>
        </RowContainer>

        <StyledSpan>
          <Checkbox onChange={CheckboxOnChangeHandler} />
          <Tooltip
            text="For a physical person, the cost of Express delivery
methods is calculated based on the actual weight of
the parcel, to the xclusion of over-dimensional parcels, in this case the delivery cost is calculated on the dimensional weight. Parcels are considered to be over-dimensional, if the sum of the three sides of the parcel  xceeds 150 cm and the weight exceeds 2 and
more times the actual weight."
          >
            <p>Except for over dimensional parcels</p>
          </Tooltip>
        </StyledSpan>

        {overDimensional && (
          <RowContainer $bottom="20px">
            <Input
              secondaryBorder
              height="43px"
              value={dimensions.width}
              onChange={(e) => handleDimensionInputChange("width", e.target.value)}
            />
            <Input
              secondaryBorder
              height="43px"
              value={dimensions.height}
              onChange={(e) => handleDimensionInputChange("height", e.target.value)}
            />
            <Input
              secondaryBorder
              height="43px"
              value={dimensions.length}
              onChange={(e) => handleDimensionInputChange("length", e.target.value)}
            />
            <Select
              options={measureOptions}
              value={selectedMeasure}
              onChange={(value) => setSelectedMeasure(value)}
            />
          </RowContainer>
        )}

        <Result>{result}$</Result>
      </ColumnContainer>
    </Modal>
  );
};

export default Calculator;
