import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoginPage } from "features/auth/authSlice";
import { forgotPassword } from "features/auth/authActions";
import Input from "components/input";
import Button from "components/button";
import { Row, Image, Text, Content, Description, MiniCol, ForgotCol } from "./Forgot.styles";
import { RowDiv, SignInUnderLine, SignUpText } from "../register/Register.styles";

const Forgot = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ value: "", isValid: true, errorMessage: "" });
  const [hasEmail, setHasEmail] = useState(false);
  const [showHelloWorld, setShowHelloWorld] = useState(false);

  const handleChangeToLogin = () => {
    dispatch(setIsLoginPage("login"));
  };

  const validateEmail = () => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value);
    setEmail({
      ...email,
      isValid: isValidEmail,
      errorMessage: isValidEmail ? "" : "Email is not valid",
    });
    return isValidEmail;
  };

  const handleSubmitForgot = () => {
    const isValidEmail = validateEmail();
    if (isValidEmail) {
      setHasEmail(true);
    }
  };

  const handleSubmitChangeToForgotEmail = () => {
    setShowHelloWorld(true);
  };

  const handleSubmitForgotEmail = () => {
    dispatch(forgotPassword(email.value));
  };

  return (
    <Row>
      <Image>
        <Text>SIGN IN</Text>
        <Text></Text>
      </Image>
      {hasEmail === false ? (
        <Content>
          <Description>
            We will send you an email with instructions to reset your password
          </Description>

          <MiniCol>
            <Input
              label="E-mail"
              type="email"
              onChange={(e) => setEmail({ ...email, value: e.target.value })}
              errorMessage={email.errorMessage}
              value={email.value}
              required
              $isValid={email.isValid}
            />
            <Button onClick={handleSubmitForgot}>Send me a letter by email</Button>
          </MiniCol>

          <RowDiv onClick={handleChangeToLogin}>
            <SignUpText>Already have an account? </SignUpText>
            <SignInUnderLine> Sign in</SignInUnderLine>
          </RowDiv>
        </Content>
      ) : (
        <Content>
          {showHelloWorld ? (
            <>
              <ForgotCol>
                <Description>
                  It looks like you already have an account with us! You can sign in using the
                  email address provided or reset your password if you've forgotten it.
                </Description>

                <Button onClick={handleSubmitForgotEmail}>Send me a letter by email</Button>
              </ForgotCol>

              <RowDiv onClick={handleChangeToLogin}>
                <SignUpText>Already have an account? </SignUpText>
                <SignInUnderLine> Sign in</SignInUnderLine>
              </RowDiv>
            </>
          ) : (
            <>
              <Description>
                We have received your request. If the email address you provided is associated
                with an account, we will send you instructions shortly on how to reset your
                password.
              </Description>

              <RowDiv>
                <Button onClick={handleChangeToLogin} width={308}>
                  Sign in
                </Button>
                <Button onClick={handleSubmitChangeToForgotEmail} width={308}>
                  Reset password
                </Button>
              </RowDiv>

              <RowDiv onClick={handleChangeToLogin}>
                <SignUpText>Already have an account? </SignUpText>
                <SignInUnderLine> Sign in</SignInUnderLine>
              </RowDiv>
            </>
          )}
        </Content>
      )}
    </Row>
  );
};

export default Forgot;
