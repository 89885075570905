import { createSlice } from "@reduxjs/toolkit";
import { getAddress } from "./addressesActions";

const initialState = {
  loading: false,
  error: null,
  address: {},
};

const addressesSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAddress.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.address = payload;
      })
      .addCase(getAddress.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.addresses.loading;
export const selectLoadingError = (state) => state.addresses.error;
export const selectAddress = (state) => state.addresses.address;

export default addressesSlice.reducer;
