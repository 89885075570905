import React, { useEffect, useState } from "react";
import Table from "../components/table/Table";
import { getTransactions } from "features/transactions/transactionsActions";
import { selectTransactions } from "features/transactions/transactionsSlice";
import {
  Container,
  Balance,
  BalanceContainer,
  Tag,
  Row,
  TransactionType,
  FullRow,
} from "./Transactions.styles";
import { formatDateTime } from "utils/dateUtils";
import ButtonPersonal from "../components/buttonPersonal/ButtonPersonal";
import { useDispatch, useSelector } from "react-redux";

const COLUMNS = [
  { key: "amount", header: "Amount" },
  { key: "type", header: "Type" },
  { key: "date", header: "Date", render: (item) => formatDateTime(item, false, true) },
  { key: "notes", header: "Notes" },
  {
    key: "status",
    header: "Status",
    render: (status) => {
      return <Tag $type={status}>{status}</Tag>;
    },
  },
];

const transactionTypes = ["All", "In  0.00", "Out  0.00"];

const Transactions = () => {
  const data = useSelector(selectTransactions) || [];

  const [activeType, setActiveType] = useState("All");

  const dispatch = useDispatch();

  const handleTypeClick = (type) => {
    setActiveType(type);
  };

  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  return (
    <Container>
      <Balance>
        My Balance:<BalanceContainer>0.00</BalanceContainer>
      </Balance>
      <FullRow>
        <Row>
          {transactionTypes.map((type) => (
            <TransactionType
              key={type}
              $active={type === activeType}
              onClick={() => handleTypeClick(type)}
            >
              {type}
            </TransactionType>
          ))}
        </Row>
        <ButtonPersonal type={"submit"}>Fill Balance</ButtonPersonal>
      </FullRow>
      <Table columns={COLUMNS} data={data} />
    </Container>
  );
};

export default Transactions;
