import styled from "styled-components";

export const NavbarContainer = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px 50px 100px;
  background-color: white;
  color: #fff;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom || 90}px`};

  @media (max-width: 768px) {
    flex-direction: ${({ isOpen }) => (isOpen ? "column" : "row")};
    padding: 10px;
    align-items: flex-start;
  }
`;

export const Logo = styled.img`
  height: 85px;
  width: auto;
  cursor: pointer;

  @media (max-width: 980px) {
    display: none;
  }
`;

export const LogoSmaller = styled.img`
  display: none;

  @media (max-width: 980px) {
    height: 40px;
    display: block;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 79px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  }
`;

export const NavLink = styled.a`
  text-decoration: none;
  color: #262757;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  color: #262757;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
`;

export const MenuIconWrapper = styled.div`
  display: none;
  cursor: pointer;
  color: #262757;

  @media (max-width: 768px) {
    display: block;
    margin: auto 0;
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  gap: 9px;
  cursor: pointer;
  width: 140px;
`;

export const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  gap: 4px;
`;

export const Name = styled.p`
  color: #262757;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 110% */
`;

////Avatar Menu
export const Container = styled.div`
  border-radius: 16px;
  border: 2px solid #262757;
  background: #fff;
  padding: 13px 20px;
  min-height: 417px;
  width: 238px;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;

  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999999;
`;

export const Line = styled.div`
  border-bottom: 1px solid #58b5e7;
  width: 100%;
  display: flex;
  padding: 10px;
  padding-bottom: 18px;
  margin-bottom: 8px;
`;
