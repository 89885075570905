import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogin } from "features/auth/authActions.js";
import { setIsLoginPage, isAuthorized, selectLogError } from "features/auth/authSlice.js";
import Modal from "components/modal";
import Input from "components/input";
import Button from "components/button";
import Checkbox from "components/checkbox";
import apple from "./../icons/apple.png";
import google from "./../icons/google.png";
import error from "../register/error.png";
import {
  RowContainer,
  ColumnContainer,
  SignUpText,
  StyleForLogin,
  ForgotLink,
  Label,
  LabelsInRow,
  StyledSignInBtn,
  Icon,
  StyledP,
  Row,
  Center,
  Remember,
  ModalRow,
} from "./Login.styles";
import { ErrorCol } from "../register/Register.styles";
import ButtonPersonal from "../../personal/components/buttonPersonal/ButtonPersonal";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrorModalOpen, setLoginErrorModalOpen] = useState(false);

  const isLoggedIn = useSelector(isAuthorized);
  const loginError = useSelector(selectLogError);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (loginError === "Username does not exist.") {
      setLoginErrorModalOpen(true);
    }
  }, [loginError]);

  const handleCloseLoginErrorModal = () => {
    setLoginErrorModalOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    dispatch(userLogin({ email, password }));
  };

  const handleChangeToRegister = () => {
    dispatch(setIsLoginPage("regStart"));
  };

  const handleNavigateForgot = () => {
    dispatch(setIsLoginPage("forgot"));
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/orders");
    }
  }, [isLoggedIn, navigate]);

  return (
    <RowContainer>
      <StyleForLogin>
        <h1>sign in</h1>
      </StyleForLogin>
      <ColumnContainer>
        <StyledSignInBtn>
          <Icon src={google} alt="google" />
          Sign in with Google
        </StyledSignInBtn>
        <StyledSignInBtn>
          <Icon src={apple} alt="apple" />
          Sign in with Apple
        </StyledSignInBtn>
        <Row>
          <hr />
          <StyledP>or sign in with e-mail</StyledP>
          <hr />
        </Row>
        <Input
          label="Username or e-mail"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <div>
          <LabelsInRow>
            <Label>Password</Label>
            <ForgotLink onClick={handleNavigateForgot}>Forgot?</ForgotLink>
          </LabelsInRow>
          <Input type="password" value={password} onChange={handlePasswordChange} required />
        </div>
        <Center>
          <SignUpText onClick={handleChangeToRegister}>
            Don’t have an account? Sign up
          </SignUpText>
        </Center>
        <Remember>
          <Checkbox />
          <p>remember me</p>
        </Remember>
        <Button onClick={handleLogin}>Log In</Button>
      </ColumnContainer>

      <Modal isOpen={loginErrorModalOpen} onClose={handleCloseLoginErrorModal} width="613px">
        <ErrorCol>
          <h1>Account does not exist</h1>
          <p>Please register</p>
          <img src={error} alt="error" />
          <ModalRow>
            <ButtonPersonal type={"cancel"} onClick={handleCloseLoginErrorModal}>
              Cancel
            </ButtonPersonal>
            <ButtonPersonal type={"submit"} onClick={handleChangeToRegister}>
              REGISTER
            </ButtonPersonal>
          </ModalRow>
        </ErrorCol>
      </Modal>
    </RowContainer>
  );
};

export default Login;
