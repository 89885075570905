import styled from "styled-components";
import { theme } from "styles/Theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 102px;
  font-weight: 840;
  text-transform: uppercase;

  span {
    color: ${theme.colors.white};
    background-color: ${theme.colors.blue};
  }
`;

export const Image = styled.img`
  max-width: 1000px;
  margin-top: -50px;
`;
