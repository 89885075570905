import { ToggleContainer, ToggleSwitch } from "./Toggle.styles";

const Toggle = ({ onToggle, isActive }) => {
  const handleClick = () => {
    onToggle(!isActive);
  };

  return (
    <ToggleContainer onClick={handleClick}>
      <ToggleSwitch $isActive={isActive} />
    </ToggleContainer>
  );
};

export default Toggle;
