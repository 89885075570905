import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
`;

export const Recipient = styled.div`
  width: 321px;
  height: 223px;
  border-radius: 16px;
  border: 2px solid #58b5e7;
  padding: 19px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
`;

export const Icon = styled.img`
  width: 37px;
  cursor: pointer;
`;

export const Items = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const AddRecipient = styled.img`
  width: 169px;
  height: 223px;

  cursor: pointer;
`;

// Add and edit recepients styles
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 37px;
`;

export const HalfScr = styled.div`
  width: 65%;
`;

export const ZipWrapper = styled.div`
  width: 30%;
`;

export const BtnsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
  gap: 24px;
`;
