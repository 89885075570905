import { Container } from "./Services.styles";
import Transportation from "./transportation";
import HowItWorks from "pages/home/howItWorks";
import OurRates from "pages/ourRates";
import Faq from "pages/faq";
import SendInquiry from "../sendInquiry";
import Download from "./download";

const Services = () => {
  return (
    <>
      <Container>
        <h1>Safe shopping in</h1>
        <h1>any country</h1>
        <h1>with us</h1>
      </Container>
      <Transportation />
      <HowItWorks />
      <Download />
      <OurRates />
      <SendInquiry />
      <Faq />
    </>
  );
};

export default Services;
