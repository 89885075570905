export const theme = {
  colors: {
    white: "#ffffff",
    black: "#000000",
    primary: "#262757",
    primaryLight: "#B2D4FF",
    primaryDark: "#0055CC",
    secondary: "#E3F5FF",
    secondaryLight: "#FFE067",
    secondaryDark: "#FFA000",
    danger: "#dc3545",
    dangerLight: "#F8D7DA",
    dangerDark: "#9f1c25",
    success: "#66A15A",
    warning: "#ffc107",
    info: "#17a2b8",
    error: "#ff0000",
    light: "#f8f9fa",
    blue: "#58B5E7",
    lightBlue: "#E9F6FE",
    dark: "#343a40",
    darkText: "#1A1A1A",
    darkGrayText: "#303030",
    gray: "#E0E0E0",
    highlight: "#dfe2e6",

    tertiary: "#303030",
    text: "#000000",
    background: "#9E9E9E",
    inputPlaceholder: "#C7C7C7",
    backgroundDark: "#e5e5e5",
    darkBackground: "#1A1A1A", // Added darkBackground color
  },

  darkMode: {
    colors: {
      white: "#ffffff",
      black: "#000000",
      primary: "#0077FF",
      primaryLight: "#B2D4FF",
      primaryDark: "#0055CC",
      secondary: "#FFC107",
      secondaryLight: "#FFE067",
      secondaryDark: "#FFA000",
      danger: "#dc3545",
      dangerLight: "#F8D7DA",
      dangerDark: "#9f1c25",
      success: "#66A15A",
      warning: "#ffc107",
      info: "#17a2b8",

      highlight: "#dfe2e6",
      light: "#f8f9fa",
      dark: "#f1f1f1",
      darkText: "#e0e0e0",
      darkGrayText: "#c7c7c7",
      tertiary: "#c7c7c7",
      background: "#1A1A1A",
      inputPlaceholder: "#464646",
    },
  },

  spacing: {
    xsmall: "0.25rem",
    small: "0.5rem",
    medium: "1rem",
    large: "1.5rem",
    xlarge: "2rem",
  },

  borderRadius: {
    small: "5px",
    medium: "8px",
    large: "16px",
  },

  paddings: {
    container: "5px",
    pageTop: "30px",
  },

  margins: {
    pageTop: "30px",
  },

  breakpoints: {
    xs: "320px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },

  typography: {
    fontFamily: {
      poppins: "Kyiv*Type Sans",
      body: "Lexend, sans-serif",
      heading: "Kyiv*Type Sans",
    },
    fontSize: {
      small: "12px",
      formLabel: "14px",
      medium: "16px",
      large: "24px",
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
    lineHeight: {
      small: "1.2",
      medium: "1.5",
      large: "1.8",
    },
  },
};
