import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${(props) => (props.size === "large" ? "#262757" : "#58b5e7")};
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div`
  ${({ size, checked }) => {
    switch (true) {
      case size === "large" && checked:
        return `
          width: 45px;
          height: 45px;
          border: 2px solid #262757;
          border-radius: 16px;
          background-color: #EEF8FE;
          color: #262757 !important;
          font-size: 40px;
        `;
      case size === "large" && !checked:
        return `
          width: 45px;
          height: 45px;
          border: 2px solid #CED4DA;
          border-radius: 16px;
          background-color: white;
          color: #262757;
        `;
      default:
        return `
          width: ${size === "large" ? "45px" : "24px"};
          height: ${size === "large" ? "45px" : "24px"};
          border: 2px solid ${checked ? "#58B5E7" : "#CED4DA"};
          border-radius: ${size === "large" ? "16px" : "8px"};
          color: ${checked ? "#FFFFFF" : "#58B5E7"};
        `;
    }
  }}
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const CheckboxLabel = styled.label`
  user-select: none;
`;
