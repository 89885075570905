import styled from "styled-components";
import Button from "./../../components/button";
import { theme } from "../../styles/Theme";

export const Box = styled.img`
  width: 534px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: 768px) {
    top: 20%;
    width: 350px;
  }
`;

export const MainText = styled.h1`
  color: ${theme.colors.primary};

  @media screen and (max-width: 1300px) and (min-width: 1000px) {
    font-size: 90px;
    z-index: 999999;
  }

  @media (min-width: 1300px) {
    font-size: 114px;
    font-weight: 840;
    line-height: 36px;
  }

  @media (max-width: 999px) {
    font-size: 30px;
    z-index: 999999;
    font-weight: 840;
    line-height: 36px;
  }
`;

export const OuterText = styled.h1`
  color: ${theme.colors.primary};
  position: absolute;
  top: 37%;
  z-index: 3;

  @media screen and (max-width: 1300px) and (min-width: 1000px) {
    font-size: 90px;
    z-index: 999999;
  }

  @media (min-width: 1300px) {
    font-size: 114px;
    font-weight: 840;
    line-height: 36px;
  }

  @media (max-width: 999px) {
    font-size: 30px;
    z-index: 999999;
    font-weight: 840;
    line-height: 36px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 574px;

  @media (max-width: 768px) {
    min-height: 360px;
  }
`;

export const LearnMoreBtn = styled(Button)`
  position: absolute;
  bottom: 12%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  /* padding: 0 120px; */

  @media (max-width: 768px) {
  }
`;

export const Padding = styled.div`
  /* padding: 0 120px; */

  @media (max-width: 768px) {
    padding: 32px;
  }
`;
