import styled, { css } from "styled-components";
import { theme } from "../../styles/Theme";

const buttonStyles = css`
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
  font-size: 16px;
  font-family: Lexend;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
`;

const defaultStyles = css`
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  border: 1px solid ${theme.colors.white};
`;

const outlinedStyles = css`
  border: 1px solid #262757;
  color: #3498db;
  background-color: white;

  &:hover {
    background-color: #ceeeff;
    // border: 1px solid #262757;
    color: white;
  }
`;

const linkStyles = css`
  border: 1px solid ${theme.colors.primary} !important;
  border-radius: 16px;
  color: ${theme.colors.primary} !important;
  background: white !important;
  width: 138px;
  height: 54px;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */

  &:hover {
    color: ${theme.colors.primary} !important;
    background: #ceeeff !important;
    border: none !important;
  }

  &:active {
    background: ${theme.colors.primary} !important;
    color: white !important;
  }
`;

const footerButton = css`
  border: 1px solid ${theme.colors.white};
  border-radius: 16px;
  color: white !important;
  background-color: ${theme.colors.primary};
  width: 138px;
  height: 54px;

  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */

  &:hover {
    color: ${theme.colors.primary} !important;
    background: #ceeeff;
    border: none;
  }

  &:active {
    background: ${theme.colors.primary};
    color: white !important;
  }
`;

const primaryStyles = css`
  ${defaultStyles}
`;

const secondaryStyles = css`
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  border: 1px solid ${theme.colors.black};
  padding: 28px 38px;
  font-size: 28px;

  &:hover {
    background-color: #8788bf;
    border: none;
  }

  &:active {
    background: ${theme.colors.primary};
    color: white;
  }
`;

export const StyledButton = styled.button`
  ${buttonStyles}

  ${({ $variant, $width, $height }) => {
    switch ($variant) {
      case "outlined":
        return outlinedStyles;
      case "link":
        return linkStyles;
      case "footerButton":
        return footerButton;
      default:
        return css`
          ${defaultStyles}
          width: ${$width}px;
          height: ${$height}px;
        `;
    }
  }}

  ${({ $type, $width, $height }) => {
    switch ($type) {
      case "secondary":
        return css`
          ${secondaryStyles}
          width: ${$width}px;
          height: ${$height}px;
        `;
      default:
        return css`
          ${primaryStyles}
          width: ${$width}px;
          height: ${$height}px;
        `;
    }
  }}
`;
