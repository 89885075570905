import styled from "styled-components";

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const StyledTooltip = styled.div`
  display: flex;
  width: 293px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  color: #000;
  font-family: "Lexend", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;

  visibility: ${(props) => (props.$isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
  transition: opacity 0.2s, visibility 0.2s;

  white-space: pre-line; /* Allow new lines */
`;
