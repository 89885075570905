import styled from "styled-components";
import background from "assets/blok1.png";
import { theme } from "styles/Theme";

export const Container = styled.div`
  min-height: 582px;
  position: relative;
  background: url(${background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  @media (max-width: 768px) {
    min-height: 180px;
  }

  h1 {
    color: ${theme.colors.primary};
    text-align: center;
    font-size: 116px;
    font-weight: 840;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }
`;

export const FirstImg = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 350px;
  height: auto;

  @media (max-width: 768px) {
    width: 100px;
  }
`;

export const SecondImg = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 350px;
  height: auto;

  @media (max-width: 768px) {
    width: 100px;
  }
`;
