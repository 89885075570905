import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransportation } from "features/transportation/transportationActions";
import { selectTransportation } from "features/transportation/transportationSlice";
import location from "assets/location.png";
import Button from "components/button";
import {
  Icon,
  Location,
  Wrapper,
  Title,
  RowContainer,
  StyledSpan,
} from "./Transportation.styles";

const Transportation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransportation());
  }, [dispatch]);

  const transportationData = useSelector(selectTransportation) || [];

  const handleClickReadMore = (url) => {
    window.open(url, "_blank");
  };

  return (
    <RowContainer>
      {transportationData?.map((transport, index) => (
        <Wrapper key={index}>
          <span>
            <Icon src={transport.icon} alt="airplane" />
          </span>
          <Title>{transport.name}</Title>
          <span>
            {transport.duration && <Location src={transportationData?.icon} alt="location" />}
            <p>{transport.duration}</p>
          </span>
          {transport.options?.map((option, index) => (
            <span key={index}>
              <Location src={location} alt="location" />
              <p>{option.text}</p>
            </span>
          ))}
          <StyledSpan>
            <Button
              variant="outlined"
              type="secondary"
              onClick={() => handleClickReadMore(transport.url)}
            >
              Read more
            </Button>
          </StyledSpan>
        </Wrapper>
      ))}
    </RowContainer>
  );
};

export default Transportation;
