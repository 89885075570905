import styled from "styled-components";

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ToggleSwitch = styled.div`
  width: 78px;
  height: 37px;
  background-color: ${(props) => (props.$isActive ? "#58B5E7" : "#E5E5E5")};
  border-radius: 30px;
  position: relative;
  transition: background-color 0.3s ease;

  &::before {
    content: "";
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${(props) => (props.$isActive ? "42px" : "4px")};
    transition: left 0.3s ease;
  }
`;
