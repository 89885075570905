import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

export const sendInquiry = createAsyncThunk(
  "/send/inquiry",
  async ({ name, email, title, your_text }, { rejectWithValue }) => {
    try {
      await ApiClient.post("/v1/inquiry", {
        name,
        email,
        title,
        your_text,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
