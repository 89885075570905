import { createSlice } from "@reduxjs/toolkit";
import { getFaq } from "./faqActions";

const initialState = {
  loading: false,
  error: null,
  faq: [],
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFaq.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.faq = payload;
      })
      .addCase(getFaq.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.faq.loading;
export const selectLoadingError = (state) => state.faq.error;
export const selectFaq = (state) => state.faq.faq;

export default faqSlice.reducer;
