// Radio.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { RadioContainer, RadioInput, RadioText } from "./Radio.styles";

const Radio = ({ label, ...props }) => {
  const [isChecked, setChecked] = useState(false);

  const handleRadioChange = () => {
    setChecked(!isChecked);
  };

  return (
    <RadioContainer>
      <RadioInput type="radio" {...props} checked={isChecked} onChange={handleRadioChange} />
      <RadioText>{label}</RadioText>
    </RadioContainer>
  );
};

Radio.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Radio;
