import styled from "styled-components";
import { theme } from "styles/Theme";

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`;

export const HomeIcon = styled.img`
  height: 68px;
  width: 68px;
`;

export const AddressRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const AddressColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const City = styled.h2`
  color: ${theme.colors.primary};
  font-size: 32px;
  font-weight: 840;
`;

export const Address = styled.p`
  color: ${theme.colors.primary};
  font-size: 23px;
  font-weight: 300;
`;
