import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import styled from "styled-components";

const Padding = styled.div`
  padding: 10px 50px;
`;

const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.accessToken !== null);
  const location = useLocation();

  const isLoginPage = location.pathname === "/login" || location.pathname === "/register";

  return isAuthenticated ? (
    <>
      {!isLoginPage && <Navbar />}
      <Padding>{children}</Padding>
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
