import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  gap: 36px;
`;

export const Country = styled.img`
  width: 33px;
  height: 33px;
`;

export const AreaWrapper = styled.div`
  p {
    color: #262757;
    font-family: Lexend;
    font-size: 19.302px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.267px; /* 105% */
  }

  padding: 10px 25px;

  gap: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  background-color: white;
  border-radius: 15.442px;
  box-shadow: 0px 3.86px 8.686px 0px rgba(70, 187, 247, 0.1);
`;

export const Title = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
`;

export const DeliveryMethod = styled.div`
  width: 225px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #eef8fe;
  align-items: center;
  justify-content: center;
  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Plane = styled.img`
  height: 72px;
  width: 81px;
`;
export const Ship = styled.img`
  height: 72px;
  width: 72px;
`;

export const Days = styled.p`
  color: #262757;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 19.2px */
`;

export const Row = styled.div`
  display: flex;
  gap: 26px;
`;

export const CountryWrapper = styled.div`
  height: 147px;
  background: #eef8fe;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 21px;
  justify-content: space-around;

  display: flex;
  align-items: center;

  padding: 42px 28px 43px 40px;
`;

export const InputWrapper = styled.div`
  height: 92px;
  background: #eef8fe;
  width: 100%;
  border-radius: 16px;
  gap: 14px;

  display: flex;
  align-items: center;
  padding: 17px 67px 16px 33px;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Label = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px; /* 0% */
`;

export const CountryLabel = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 35px;
`;

export const LabelWrapepr = styled.div`
  width: 50%;
`;

export const Actions = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  justify-content: end;
  padding-top: 38px;
`;
