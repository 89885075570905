import styled from "styled-components";
import { theme } from "../../styles/Theme";

export const Container = styled.div`
  background-color: ${theme.colors.primary};
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${theme.colors.white};
  font-size: 102px;
  font-weight: 840;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 50px;
    font-weight: 840;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Wrapper = styled.div`
  padding: 60px;

  @media (max-width: 768px) {
    padding: 32px;
  }
`;

export const Order = styled.h1`
  color: ${theme.colors.white};
  font-family: Kyiv * Type Sans;
  font-size: 54px;
  font-weight: 840;
`;

export const Line = styled.hr`
  height: 2px;
  background: ${theme.colors.white};
`;

export const SmallerTitle = styled.h1`
  color: ${theme.colors.white};
  font-size: 28px;
  font-weight: 840;
`;

export const Description = styled.p`
  color: ${theme.colors.white};
  font-size: 20px;
  font-weight: 400;
`;

export const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 400px;
  width: 30%;
  padding: 12px;
`;

export const RowContainer = styled.div`
  padding: 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 32px;
  }
`;
