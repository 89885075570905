import styled from "styled-components";
import { theme } from "styles/Theme";

export const Icon = styled.img`
  height: 165px;
  margin-bottom: 40px;
`;

export const Location = styled.img`
  width: 48px;
  height: 48px;
`;

export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 42px;
  font-style: normal;
  font-weight: 840;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  max-width: 30%;
  padding: 12px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }

  p {
    color: ${theme.colors.primary};
    font-size: 24px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 100px 0;
`;

export const StyledSpan = styled.span`
  padding-top: 61px;
`;
