import { theme } from "styles/Theme";
import styled from "styled-components";

export const AccordionContainer = styled.div`
  margin: 20px;
  padding: 20px 40px;
  font-size: 34px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 998px;
`;

export const AccordionHeader = styled.div`
  border: 1px solid black;
  border-radius: 16px;
  display: flex;
  align-items: center;
  color: ${theme.colors.primary};
  padding: 10px;
  cursor: pointer;
  height: 77px;
  background-color: ${(props) => (props.$isOpen ? "#D3F0FF" : "transparent")};

  font-family: Lexend;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
`;

export const AccordionContent = styled.div`
  display: ${(props) => (props.$isOpen ? "block" : "none")};
  border-left: 2px solid #262757;
  padding: 10px;
  margin: 10px 50px;

  p {
    color: #262757;
    font-family: Lexend;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 23.1px */
  }
`;
