import React from "react";
import { AddressWrapper } from "../Contact.styles";
import {
  HomeIcon,
  AddressRowWrapper,
  AddressColumnWrapper,
  City,
  Address,
} from "./Map.styles";
import homeIcon from "assets/home-antd.png";

const addressesData = [
  { city: "Dublin", address: "18A Armagh Rd, Kimmage" },
  { city: "Dublin", address: "18A Armagh Rd, Kimmage" },
  { city: "Dublin", address: "18A Armagh Rd, Kimmage" },
  { city: "Dublin", address: "18A Armagh Rd, Kimmage" },
  { city: "Dublin", address: "18A Armagh Rd, Kimmage" },
  { city: "Dublin", address: "18A Armagh Rd, Kimmage" },
];

const Addresses = () => {
  return (
    <AddressWrapper>
      {addressesData?.map((address, index) => (
        <AddressRowWrapper key={index}>
          <HomeIcon src={homeIcon} alt="homeIcon" />
          <AddressColumnWrapper>
            <City>{address.city}</City>
            <Address>{address.address}</Address>
          </AddressColumnWrapper>
        </AddressRowWrapper>
      ))}
    </AddressWrapper>
  );
};

export default Addresses;
