import styled from "styled-components";

export const Container = styled.div`
  margin-top: -60px !important;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  gap: 230px;
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-top: -60px !important;
  max-width: 1500px;
  margin: 0 auto;
  width: 100%;
`;
