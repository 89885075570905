import styled from "styled-components";

export const Container = styled.div`
  min-height: 420px;
  height: calc(100%-80px);
  border-right: 3px solid #58b5e7;
  width: 297px;
  padding-top: 35px;

  justify-content: space-between;

  display: flex;
  flex-direction: column;
`;

export const UserInfo = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;

  position: relative;

  p {
    color: #262757;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
`;

export const AvatarShape = styled.div`
  border-radius: 9999999px;
  background: #58b5e7;
  width: 120px;
  height: 120px;
`;

export const Avatar = styled.img`
  width: 122px;
  height: 122px;
  border-radius: 999999px;
  object-fit: cover;
  /* left: 7px; */
  /* bottom: 9px; */
  right: -7px;
  top: -9px;

  position: absolute;
  z-index: 9999;
`;

export const NavItem = styled.div`
  height: 57px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.$active ? "#CEEEFF" : "transparent")};

  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 110% */
  width: 100%;

  &:hover {
    background-color: #ceeeff;
    width: 100%;
    min-width: 190px;
    border-radius: ${(props) => (props.$noHoverRadius ? null : "16px")};
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  max-width: 28px;
`;
