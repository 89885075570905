import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../upload";
import { userLogout } from "features/auth/authActions";
import { getNotifications } from "features/instructions/instructionsActions";
import { selectUserInfo } from "features/auth/authSlice";
import { selectPersonalNavItems, setNavItems } from "features/navigation/navigationSlice";
import { UserInfo, AvatarShape, Container, NavItem, Icon, Avatar } from "./Navbar.styles";
import exit from "./exit.png";

const Navbar = () => {
  const navItems = useSelector(selectPersonalNavItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = useSelector(selectUserInfo);

  const handleItemClick = (clickedId) => {
    dispatch(setNavItems(clickedId));

    switch (clickedId) {
      case 1:
        navigate("/orders");
        break;
      case 2:
        navigate("/personal-info");
        break;
      case 3:
        navigate("/recipients");
        break;
      case 4:
        navigate("/notifications");
        dispatch(getNotifications());
        break;
      case 5:
        navigate("/transactions");
        break;
      case 6:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  const handleClickLogout = () => {
    dispatch(userLogout());
    navigate("/login");
  };

  return (
    <div>
      <UserInfo>
        <p>
          {userInfo?.first_name} {userInfo?.last_name?.charAt(0)}.
        </p>
        <AvatarShape />
        {userInfo?.url && <Avatar alt="avatar" src={userInfo?.url} />}
        {location.pathname === "/personal-info" && <Upload />}
      </UserInfo>
      <Container>
        <div>
          {navItems.map((item) => (
            <div key={item.id}>
              <NavItem
                $noHoverRadius
                key={item.id}
                $active={
                  item.active ||
                  location.pathname === `/${item.label.toLowerCase().replace(/\s+/g, "-")}`
                }
                onClick={() =>
                  handleItemClick(item.id, `/${item.label.toLowerCase().replace(/\s+/g, "-")}`)
                }
              >
                <Icon src={item.src} />
                {item.label}
              </NavItem>
            </div>
          ))}
        </div>
        <div>
          <NavItem onClick={handleClickLogout} $noHoverRadius>
            <Icon src={exit} />
            Exit
          </NavItem>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
