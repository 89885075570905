import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAddress } from "features/addresses/addressesActions";
import { selectAddress } from "features/addresses/addressesSlice";
import MyFieldItem from "components/myFieldItem";
import usa from "assets/usa.png";
import uk from "assets/uk.png";
import china from "assets/china.png";
import germany from "assets/germany.png";
import plane from "./plane.png";
import ship from "./ship.png";
import { DataTypeWrapper } from "./../ordersTable/OrdersTable.styles";
import {
  AddressContainer,
  InfoContainer,
  RowContainer,
  AddressesRow,
  Description,
  DataType,
  Wrapper,
  Column,
  Plane,
  Title,
  Ship,
  Flag,
  Row,
} from "./Addresses.styles";

const Addresses = () => {
  const [activeDataType, setActiveDataType] = useState("USA");
  const data = useSelector(selectAddress);
  let addresses = [];

  switch (activeDataType) {
    case "USA":
      addresses = data.usa;
      break;
    case "China":
      addresses = data.china;
      break;
    case "Germany":
      addresses = data.germany;
      break;
    case "United Kingdom":
      addresses = data.uk;
      break;
    // Add more cases for other countries if needed
    default:
      addresses = []; // or a default value
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAddress());
  }, []);

  const handleDataTypeClick = (dataType) => {
    setActiveDataType(dataType);
  };

  return (
    <RowContainer>
      <DataTypeWrapper>
        <DataType
          onClick={() => handleDataTypeClick("USA")}
          $active={activeDataType === "USA"}
        >
          <Flag src={usa} alt="usa" />
          USA
        </DataType>
        <DataType
          onClick={() => handleDataTypeClick("China")}
          $active={activeDataType === "China"}
        >
          <Flag src={china} alt="china" />
          China
        </DataType>
        <DataType
          onClick={() => handleDataTypeClick("United Kingdom")}
          $active={activeDataType === "United Kingdom"}
        >
          <Flag src={uk} alt="uk" />
          United Kindom
        </DataType>
        <DataType
          onClick={() => handleDataTypeClick("Germany")}
          $active={activeDataType === "Germany"}
        >
          <Flag src={germany} alt="germany" />
          Germany
        </DataType>
      </DataTypeWrapper>
      <AddressContainer>
        <Row>
          <Wrapper>
            <Title>Your address in {activeDataType}</Title>
          </Wrapper>
          <Wrapper $hasBorder={true}>
            <Description>
              Enter this address as your shipping address when shopping from the USA online
              shops
            </Description>
          </Wrapper>
        </Row>
        <AddressesRow>
          {data && <MyFieldItem data={addresses} />}
          <Column>
            <InfoContainer>
              <Plane src={plane} />
              <Column>
                <p>20$/kg</p>
                <p>7-10 days</p>
                <p>5 times a week</p>
              </Column>
            </InfoContainer>
            <InfoContainer>
              <Ship src={ship} />
              <Column>
                <p>10$/kg</p>
                <p>60-85 days</p>
                <p>2 times a week</p>
              </Column>
            </InfoContainer>
          </Column>
        </AddressesRow>
      </AddressContainer>
    </RowContainer>
  );
};

export default Addresses;
