import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Layout from "components/layout";
import PersonalLayout from "pages/personal/components/personalLayout";
import Home from "pages/home";
import Services from "pages/services";
import AboutUs from "pages/aboutUs";
import Contact from "pages/contact";
import SignIn from "pages/signIn";
import Orders from "pages/personal/orders";
import AddOrders from "pages/personal/orders/addOrders";
import PersonalInfo from "pages/personal/personalInfo";
import Recipients from "pages/personal/recipients";
import AddRecipients from "pages/personal/recipients/AddRecipients";
import Notifications from "pages/personal/notifications";
import Transactions from "pages/personal/transactions";
import Settings from "pages/personal/settings";
import New from "pages/signIn/forgot/New";
import { Wrapper } from "pages/personal/components/personalLayout/PersonalLayout.styles";

const AppRoute = () => {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/login"
          element={
            <Layout>
              <SignIn />
            </Layout>
          }
        />
        <Route
          exact
          path="/password/new"
          element={
            <Layout>
              <New />
            </Layout>
          }
        />
        <Route
          exact
          path="/home"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          exact
          path="/services"
          element={
            <Layout>
              <Services />
            </Layout>
          }
        />
        <Route
          exact
          path="/about"
          element={
            <Layout>
              <AboutUs />
            </Layout>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
        <Route
          exact
          path="/orders"
          element={
            <PrivateRoute>
              <Wrapper>
                <Orders />
              </Wrapper>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/add-orders"
          element={
            <PrivateRoute>
              <Wrapper>
                <AddOrders />
              </Wrapper>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/personal-info"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <PersonalInfo />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/recipients"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <Recipients />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/recipients/add"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <AddRecipients />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/recipients/:id"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <AddRecipients />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/notifications"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <Notifications />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/transactions"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <Transactions />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/settings"
          element={
            <PrivateRoute>
              <PersonalLayout>
                <Settings />
              </PersonalLayout>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoute;
