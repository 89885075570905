import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from "./ordersActions";

const initialState = {
  loading: false,
  error: null,
  orders: [],
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.orders = payload;
      })
      .addCase(getOrders.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.orders.loading;
export const selectLoadingError = (state) => state.orders.error;
export const selectOrders = (state) => state.orders.orders;

export default ordersSlice.reducer;
