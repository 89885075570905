import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectHowItWorks } from "features/howItWorks/howItWorksSlice";
import { getHowItWorks } from "features/howItWorks/howItWorksActions";
import {
  ColumnContainer,
  RowContainer,
  StyledTitle,
  Order,
  Description,
  RowWrapper,
} from "./HowItWorks.styles";

const Step = ({ order, text }) => (
  <RowWrapper>
    <Order>{order}.</Order>
    <Description>{text}</Description>
  </RowWrapper>
);

const HowItWorks = () => {
  const steps = useSelector(selectHowItWorks) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHowItWorks());
  }, [dispatch]);

  return (
    <RowContainer>
      <ColumnContainer>
        <StyledTitle>HOW IT WORKS</StyledTitle>
      </ColumnContainer>

      <ColumnContainer>
        {steps?.length && steps.slice(0, 2).map((step) => <Step key={step.order} {...step} />)}
      </ColumnContainer>

      <ColumnContainer>
        {steps?.length && steps.slice(2).map((step) => <Step key={step.order} {...step} />)}
      </ColumnContainer>
    </RowContainer>
  );
};

export default HowItWorks;
