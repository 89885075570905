import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationFetch,
  getNotifications,
} from "features/instructions/instructionsActions";
import {
  selectNotifications,
  selectLoading,
  setNotifications,
} from "features/instructions/instructionsSlice";
import CircularProgress from "@mui/material/CircularProgress";
import Toggle from "../components/toggle";
import Checkbox from "components/checkbox";
import { Column, Container, Row, Title } from "./Notifications.styles";

const Notifications = () => {
  const notifications = useSelector(selectNotifications);
  const [isActive, setIsActive] = useState(false);

  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);

  const handleToggle = () => {
    setIsActive(!isActive);
    if (notifications?.length !== 0) {
      dispatch(notificationFetch([]));
    } else {
      setIsActive(false);
    }
  };

  const handleCheckboxChange = (id) => {
    setIsActive(false);
    const newNotifications = notifications.includes(id)
      ? notifications.filter((checkedId) => checkedId !== id)
      : [...notifications, id];

    dispatch(setNotifications(newNotifications));
    dispatch(notificationFetch(newNotifications));
  };

  const isCheckboxChecked = (id) => {
    return notifications.includes(id);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  useEffect(() => {
    setIsActive(notifications?.length === 0);
  }, [notifications]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Column>
          <Container>
            <Column>
              <Title>Notification status</Title>
              <Row>
                <Checkbox
                  id={1}
                  onChange={() => handleCheckboxChange(1)}
                  checked={isCheckboxChecked(1)}
                />
                <p>Parcel Registration Notification</p>
              </Row>
              <Row>
                <Checkbox
                  id={2}
                  onChange={() => handleCheckboxChange(2)}
                  checked={isCheckboxChecked(2)}
                />
                <p>Received in Int. Warehouse</p>
              </Row>
              <Row>
                <Checkbox
                  id={3}
                  onChange={() => handleCheckboxChange(3)}
                  checked={isCheckboxChecked(3)}
                />
                <p>Shipped Notification</p>
              </Row>
            </Column>

            <Column>
              <Title>Transaction notifications</Title>
              <Row>
                <Checkbox
                  id={4}
                  onChange={() => handleCheckboxChange(4)}
                  checked={isCheckboxChecked(4)}
                />
                <p>Fill Balance Notification</p>
              </Row>
            </Column>
          </Container>
          <Row $marginTop="58px">
            <Toggle onToggle={handleToggle} isActive={isActive} />
            <p>Disable All Notification</p>
          </Row>
        </Column>
      )}
    </>
  );
};

export default Notifications;
