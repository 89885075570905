import React from "react";
import styled from "styled-components";
import { theme } from "styles/Theme";

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 12px 0; /* Adjust the spacing as needed */

  color: #262757;
  font-family: Lexend;
  font-size: 31.443px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 34.587px */
  text-transform: capitalize;
`;

const TableCell = styled.td`
  padding: 10px 30px;
  text-align: center;
  border-bottom: 1px solid ${theme.colors.blue};
  background-color: ${(props) =>
    props.$colIndex !== 0 ? `${theme.colors.lightBlue}` : "transparent"};

  &:first-child {
    text-align: start;
  }
`;

const TableHeader = styled.th`
  padding: 10px 30px;
  color: #262757;
  font-family: Lexend;
  font-size: 31.443px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  text-transform: capitalize;
  background-color: ${(props) =>
    props.$colIndex !== 0 ? `${theme.colors.lightBlue}` : "transparent"};

  span {
    border-bottom: 1.5px solid ${theme.colors.black};
    padding: 4px 15px;
    display: none;
  }

  ${(props) =>
    props.$colIndex !== 0 &&
    `
    span {
      display: block;
    }
  `}
`;

const Table = ({ columns, data }) => {
  return (
    <TableWrapper>
      <thead>
        <tr>
          {columns?.map((column, index) => (
            <TableHeader key={index} $colIndex={index}>
              <span>{column.header}</span>
            </TableHeader>
          ))}
        </tr>
      </thead>

      <tbody>
        {data?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns?.map((column, colIndex) => (
              <TableCell key={colIndex} $colIndex={colIndex}>
                {row[column.key]}
              </TableCell>
            ))}
          </tr>
        ))}
      </tbody>
    </TableWrapper>
  );
};

export default Table;
