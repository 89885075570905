import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;

  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  gap: 37px;
  width: 100%;
  justify-content: space-between;

  align-items: center;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .font {
    color: #262757 !important;
    font-family: Lexend !important;
    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 34px !important;
  }
`;

export const Label = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34.5px; /* 172.5% */
`;
