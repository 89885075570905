import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  color: red;

  span {
    margin-left: 5px;
  }
`;

export const MyForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 40px;
  width: 100%;
`;
