import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isAuthorized } from "features/auth/authSlice";
import { selectFooter } from "features/navigation/navigationSlice";
import { getFooter } from "features/navigation/navigationActions";
import { getFooterMeta } from "features/transportation/transportationActions";
import { selectFooterMeta } from "features/transportation/transportationSlice";
import Button from "../button";
import { Container, NavLink, NavLinks } from "./Footer.styles";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(isAuthorized);
  const links = useSelector(selectFooter);
  const meta = useSelector(selectFooterMeta);

  const sortedMenuItems = [...links].sort((a, b) => a.order - b.order);

  const handleNavigate = (link) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(getFooter());
    dispatch(getFooterMeta());
  }, []);

  return (
    <Container>
      <NavLinks>
        {sortedMenuItems?.map((link, index) => (
          <NavLink key={index} onClick={() => handleNavigate(link.link)}>
            {link.name}
          </NavLink>
        ))}
        {!isLoggedIn && (
          <Button onClick={() => handleNavigate("/login")} variant="footerButton">
            Sign In
          </Button>
        )}
      </NavLinks>

      <NavLinks>
        <NavLink href={`tel:${meta?.phone}`}>{meta?.phone}</NavLink>
        <NavLink href={`mailto:${meta?.email}`}>{meta?.email}</NavLink>
      </NavLinks>
    </Container>
  );
};

export default Footer;
