import { createSlice } from "@reduxjs/toolkit";
import { getHowItWorks } from "./howItWorksActions";

const initialState = {
  loading: false,
  error: null,
  howItWorks: [],
};

const howItWorksSlice = createSlice({
  name: "howItWorks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHowItWorks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHowItWorks.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.howItWorks = payload;
      })
      .addCase(getHowItWorks.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.howItWorks.loading;
export const selectLoadingError = (state) => state.howItWorks.error;
export const selectHowItWorks = (state) => state.howItWorks.howItWorks;

export default howItWorksSlice.reducer;
