import styled from "styled-components";
import signUpPhotoWPpl from "assets/login/signup-photo-ppl.png";

export const TermContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 32px;
  padding-right: 32px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

export const Image = styled.div`
  width: 100%;
  background-image: url(${signUpPhotoWPpl});
  background-size: contain;
  background-repeat: no-repeat;

  h1 {
    color: #fff;
    text-align: center;
    font-size: 116px;
    font-style: normal;
    font-weight: 840;
    line-height: 116px; /* 100% */
    text-transform: uppercase;
    margin-top: -23px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledP = styled.p`
  color: #6d6562;
  text-align: center;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const ModalColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-bottom: 60px;

  h4 {
    color: #262757;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px; /* 175% */
    text-align: center;
  }
`;

export const RowTerm = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  p {
    color: #262757;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 34.5px; /* 172.5% */
  }
`;

export const Conditions = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 34.5px;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const SignUpText = styled.p`
  color: #6d6562;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const SignInUnderLine = styled.p`
  color: #6d6562;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
`;

export const RowDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

// error modal styles

export const ErrorCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding-bottom: 45px;

  img {
    width: 268px;
    height: 183px;
  }

  h1 {
    color: #000;
    text-align: center;
    font-family: Lexend;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 34.5px; /* 95.833% */
  }

  p {
    color: #262757;
    text-align: center;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px; /* 105% */
  }
`;
