import styled from "styled-components";
import { theme } from "styles/Theme";
import background from "assets/what-are-we-doing.png";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 54px;
  padding-bottom: 155px;
  background: url(${background});
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
`;

export const Title = styled.h1`
  color: ${theme.colors.primary};
  text-align: center;
  font-size: 116px;
  font-weight: 840;
  text-transform: uppercase;
  width: 60%;
`;

export const Description = styled.p`
  color: ${theme.colors.primary};
  text-align: center;
  font-size: 24px;
  width: 45%;
`;
