import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const Balance = styled.h2`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 87.5% */
`;

export const BalanceContainer = styled.div`
  display: inline-flex;
  height: 42px;
  padding: 10px 43px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 13px;

  border-radius: 16px;
  background: rgba(88, 181, 231, 0.29);
`;

export const Tag = styled.div`
  width: 125px;
  height: 36px;
  border-radius: 16px;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.$type === "Approved" ? "#E3EC9C" : "#FFD1CD")};

  color: #262757;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

export const Row = styled.div`
  width: 50%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const FullRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const TransactionType = styled.div`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  display: inline-flex;
  height: 42px;
  padding: 10px 43px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 16px;
  border: 2px solid ${(props) => (props.$active ? "#58b5e7" : "transparent")};
  background: ${(props) => (props.$active ? "#fff" : "transparent")};

  cursor: pointer;
`;
