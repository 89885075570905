import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNavItems, selectPersonalNavItems } from "features/navigation/navigationSlice";
import { getNotifications } from "features/instructions/instructionsActions";
import { userLogout } from "features/auth/authActions";
import { selectUserInfo } from "features/auth/authSlice";
import { NavItem, Icon } from "../../pages/personal/components/navbar/Navbar.styles";
import { AvatarWrapper, Name, PersonalInfo, Container, Line } from "./Navbar.style";
import Avatar from "@mui/material/Avatar";
import exit from "./exit.png";

const AvatarMenu = () => {
  const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState(false);
  const containerRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navItems = useSelector(selectPersonalNavItems);
  const userInfo = useSelector(selectUserInfo);

  const handleItemClick = (clickedId) => {
    dispatch(setNavItems(clickedId));

    switch (clickedId) {
      case 1:
        navigate("/orders");
        break;
      case 2:
        navigate("/personal-info");
        break;
      case 3:
        navigate("/recipients");
        break;
      case 4:
        dispatch(getNotifications());
        navigate("/notifications");

        break;
      case 5:
        navigate("/transactions");
        break;
      case 6:
        navigate("/settings");
        break;
      default:
        break;
    }
  };

  const handleClickLogout = () => {
    dispatch(userLogout());
    navigate("/login");
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsAvatarMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!isAvatarMenuOpen ? (
        <AvatarWrapper onClick={() => setIsAvatarMenuOpen(!isAvatarMenuOpen)}>
          <Avatar
            alt={userInfo?.first_name}
            src={userInfo?.url}
            sx={{ width: 54, height: 54 }}
            onClick={() => setIsAvatarMenuOpen(!isAvatarMenuOpen)}
          />
          <PersonalInfo>
            <Name>
              {userInfo?.first_name} {userInfo?.last_name?.charAt(0)}.
            </Name>
          </PersonalInfo>
        </AvatarWrapper>
      ) : (
        <>
          <AvatarWrapper />
          <Container ref={containerRef}>
            <Line>
              <Avatar
                alt={userInfo?.first_name}
                src={userInfo?.url}
                onClick={() => setIsAvatarMenuOpen(!isAvatarMenuOpen)}
                sx={{ width: 54, height: 54 }}
              />
              <PersonalInfo>
                <Name>
                  {userInfo?.first_name} {userInfo?.last_name?.charAt(0)}.
                </Name>
              </PersonalInfo>
            </Line>

            {navItems.map((item) => (
              <div key={item.id}>
                <NavItem key={item.id} onClick={() => handleItemClick(item.id)}>
                  <Icon src={item.src} />
                  {item.label}
                </NavItem>
              </div>
            ))}
            <div>
              <NavItem onClick={handleClickLogout}>
                <Icon src={exit} />
                Exit
              </NavItem>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default AvatarMenu;
