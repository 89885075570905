import { createSlice } from "@reduxjs/toolkit";
import {
  getTransportation,
  getFooterMeta,
  getWhatDoing,
  getDelivery,
  getTeam,
} from "./transportationActions";

const initialState = {
  loading: false,
  error: null,
  transportation: [],
  whatDoing: {},
  footerMeta: {},
  delivery: {},
  team: {},
};

const transportationSlice = createSlice({
  name: "transportation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransportation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTransportation.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.transportation = payload;
      })
      .addCase(getTransportation.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getWhatDoing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWhatDoing.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.whatDoing = payload;
      })
      .addCase(getWhatDoing.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getFooterMeta.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFooterMeta.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.footerMeta = payload;
      })
      .addCase(getFooterMeta.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getDelivery.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDelivery.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.delivery = payload;
      })
      .addCase(getDelivery.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getTeam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTeam.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.team = payload;
      })
      .addCase(getTeam.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.transportation.loading;
export const selectLoadingError = (state) => state.transportation.error;
export const selectTransportation = (state) => state.transportation.transportation;
export const selectWhatDoing = (state) => state.transportation.whatDoing;
export const selectFooterMeta = (state) => state.transportation.footerMeta;
export const selectDelivery = (state) => state.transportation.delivery;
export const selectTeam = (state) => state.transportation.team;

export default transportationSlice.reducer;
