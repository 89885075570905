import { useSelector, useDispatch } from "react-redux";
import { selectContact } from "features/contact/contactSlice";
import { getContact } from "features/contact/contactActions";
import {
  Title,
  AddressWrapper,
  Line,
  WorkingDays,
  PhoneNumber,
  Email,
  AddressName,
  RowContainer,
  Row,
} from "./Contact.styles";
import { Padding } from "pages/home/Home.styles";
import Map from "./map";
import Addresses from "./map/Addresses";
import { useEffect } from "react";

const Contact = () => {
  const addresses = useSelector(selectContact);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContact());
  }, [dispatch]);

  const renderAddress = (address) => (
    <AddressWrapper key={address.name}>
      <AddressName>{address.name}</AddressName>
      <Line />
      <WorkingDays>{address.working_hours}</WorkingDays>
      <PhoneNumber href={`tel:${address.phone_number}`}>{address.phone_number}</PhoneNumber>
      <Email href={`mailto:${address.email}`}>{address.email}</Email>
    </AddressWrapper>
  );

  return (
    <Padding>
      <Title>Contact Us</Title>
      <RowContainer>{addresses?.map(renderAddress)}</RowContainer>
      <Row>
        <Map />
        <Addresses />
      </Row>
    </Padding>
  );
};

export default Contact;
