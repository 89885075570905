import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "utils/dateUtils";
import DatePicker from "components/datePicker";
import Input from "components/input";
import { Select } from "../components/select";
import { selectUserInfo } from "features/auth/authSlice";
import { Container, Label, LabelWrapper, Row } from "./PersonalInfo.styles";
import { BtnsWrapper } from "../recipients/Recipients.styles";
import ButtonPersonal from "../components/buttonPersonal/ButtonPersonal";
import { updateUserInfo } from "features/auth/authActions";
import { getUserInfo } from "features/auth/authActions";
import camera from "./camera.png";

const PersonalInfo = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const [formData, setFormData] = useState({
    user_id: userInfo.user_id || null,
    email: userInfo.email || "",
    first_name: userInfo.first_name || "",
    last_name: userInfo.last_name || "",
    birthday: userInfo.birthday || null,
    sex: userInfo.sex || "",
    phone_number: userInfo.phone_number || "",
    receiver_address: userInfo.receiver_address || "",
    passport: userInfo.passport || "",
    username: userInfo.username || "",
  });

  useEffect(() => {
    if (userInfo) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: userInfo.user_id || null,
        email: userInfo.email || "",
        first_name: userInfo.first_name || "",
        last_name: userInfo.last_name || "",
        birthday: userInfo.birthday || null,
        sex: userInfo.sex || "",
        phone_number: userInfo.phone_number || "",
        receiver_address: userInfo.receiver_address || "",
        passport: userInfo.passport || "",
        username: userInfo.username || "",
      }));
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const handleInputChange = (label, value) => {
    const selectedValue = value && value.value !== undefined ? value.value : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [label]: selectedValue,
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        birthday: formatDateTime(date, true, true),
      }));
    }
  };

  const handleCalendarOpen = () => {};

  const handleCalendarClose = () => {};

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const handleSubmit = () => {
    dispatch(updateUserInfo(formData));
  };

  return (
    <Container>
      <Row>
        <Input
          label="Name"
          value={formData?.first_name}
          onChange={(event) => handleInputChange("first_name", event.target.value)}
        />
        <Input
          label="Surname"
          value={formData?.last_name}
          onChange={(event) => handleInputChange("last_name", event.target.value)}
        />
        <Input
          label="Username"
          value={formData?.username}
          onChange={(event) => handleInputChange("username", event.target.value)}
        />
      </Row>

      <Row>
        <LabelWrapper>
          <Label>Birthday</Label>
          <DatePicker
            value={formData.birthday}
            onChange={handleDateChange}
            handleCalendarOpen={handleCalendarOpen}
            handleCalendarClose={handleCalendarClose}
            placeholderText="dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
          />
        </LabelWrapper>

        <LabelWrapper>
          <Label>Sex</Label>
          <Select
            className="font"
            options={genderOptions}
            value={{ value: formData.sex, label: formData.sex }}
            onChange={(value) => handleInputChange("sex", value)}
          />
        </LabelWrapper>

        <Input
          label="Phone number"
          value={formData?.phone_number}
          onChange={(event) => handleInputChange("phone_number", event.target.value)}
        />
      </Row>

      <Row>
        <Input
          label="Email"
          value={formData?.email}
          onChange={(event) => handleInputChange("email", event.target.value)}
        />
        <Input
          label="Passport"
          value={formData?.passport}
          onChange={(event) => handleInputChange("passport", event.target.value)}
          suffix={<img src={camera} alt="camera" />}
        />
        <LabelWrapper></LabelWrapper>
      </Row>

      <Row>
        <Input
          label="Receiving address"
          value={formData?.receiver_address}
          onChange={(event) => handleInputChange("receiver_address", event.target.value)}
        />
        <LabelWrapper></LabelWrapper>
      </Row>
      <BtnsWrapper>
        <ButtonPersonal width={208} type={"cancel"}>
          Cancel
        </ButtonPersonal>
        <ButtonPersonal width={208} type={"submit"} onClick={handleSubmit}>
          Save
        </ButtonPersonal>
      </BtnsWrapper>
    </Container>
  );
};

export default PersonalInfo;
