import styled from "styled-components";
import forgot from "assets/login/forgot.png";

export const Image = styled.div`
  background-image: url(${forgot});
  background-size: contain;
  background-repeat: no-repeat;

  min-height: 575px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 55px;
`;

export const Text = styled.h1`
  color: #fff;
  text-align: center;
  font-family: "Kyiv*Type Sans";
  font-size: 116px;
  font-style: normal;
  font-weight: 840;
  line-height: 116px; /* 100% */
  text-transform: uppercase;

  margin-top: -23px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  max-height: 350px;

  padding-top: 20px;
`;

export const Description = styled.p`
  color: #262757;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const MiniCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 27px;
`;

export const ForgotCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 78px;
  margin-bottom: -50px;
`;
