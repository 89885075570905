import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

export const getRecipients = createAsyncThunk(
  "recipients/info",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ApiClient.get("/v1/recipients");
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addRecipients = createAsyncThunk(
  "/add/recipient",
  async (
    { name, surname, country, city, state, address, phone_number, zip_code },
    { rejectWithValue }
  ) => {
    try {
      await ApiClient.post("/v1/recipients/", {
        name,
        surname,
        country,
        city,
        state,
        address,
        phone_number,
        zip_code,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editRecipient = createAsyncThunk(
  "/add/recipient",
  async (
    { id, name, surname, country, city, state, address, phone_number, zip_code },
    { rejectWithValue }
  ) => {
    try {
      await ApiClient.patch("/v1/recipients/", {
        recipient_id: id,
        name,
        surname,
        country,
        city,
        state,
        address,
        phone_number,
        zip_code,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
