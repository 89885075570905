import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatDateTime } from "utils/dateUtils.js";
import { selectOrders } from "features/orders/ordersSlice";
import { getOrders } from "features/orders/ordersActions";
import Table from "../../components/table";
import { DataTypeWrapper, RowContainer, DataType } from "./OrdersTable.styles";

const COLUMNS = [
  { key: "tracking_number", header: "Tracking Number" },
  { key: "name", header: "Name" },
  { key: "shipped_method", header: "Shipping method" },
  {
    key: "added_at",
    header: "Added",
    render: (item) => formatDateTime(item, false, true),
  },
  { key: "status", header: "Status" },
];

const OrdersTable = () => {
  const [activeDataType, setActiveDataType] = useState("Entered");

  const data = useSelector(selectOrders);
  let ordersData = [];

  switch (activeDataType) {
    case "Entered":
      ordersData = data.Entered;
      break;
    case "In Warehouse":
      ordersData = data.On_the_way;
      break;
    case "On the way":
      ordersData = data.Received;
      break;
    case "Received":
      ordersData = data.Warehouse;
      break;
    default:
      ordersData = [];
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  const handleDataTypeClick = (dataType) => {
    setActiveDataType(dataType);
  };

  return (
    <RowContainer>
      <DataTypeWrapper>
        <DataType
          onClick={() => handleDataTypeClick("Entered")}
          $active={activeDataType === "Entered"}
        >
          Entered
        </DataType>
        <DataType
          onClick={() => handleDataTypeClick("In Warehouse")}
          $active={activeDataType === "In Warehouse"}
        >
          In Warehouse
        </DataType>
        <DataType
          onClick={() => handleDataTypeClick("On the way")}
          $active={activeDataType === "On the way"}
        >
          On the way
        </DataType>
        <DataType
          onClick={() => handleDataTypeClick("Received")}
          $active={activeDataType === "Received"}
        >
          Received
        </DataType>
      </DataTypeWrapper>
      <Table data={ordersData} columns={COLUMNS} />
    </RowContainer>
  );
};

export default OrdersTable;
