import styled from "styled-components";
import { theme } from "styles/Theme";

export const Container = styled.div`
  background-color: ${theme.colors.primary};
  min-height: 150px;
  padding: 91px 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 58px;

  @media (max-width: 768px) {
    padding: 32px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
`;

export const Wrapper = styled.div`
  border-radius: 16px;
  border: 4px solid #fff;
  width: 30%;
  height: 200px;
  padding: 30px 76px 20px 86px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  border-bottom: 3px solid #fff;
  padding-bottom: 19px;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  height: ${(props) => `${props.height}px`};
  max-height: 100px;
`;

export const DownloadAction = styled.p`
  color: #fff;
  text-align: center;
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const Description = styled.p`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;
