import { createSlice } from "@reduxjs/toolkit";
import { getInstructions, getNotifications } from "./instructionsActions";

const initialState = {
  loading: false,
  notifLoading: false,
  error: null,
  notifications: [],
  data: [],
};

const instructionsSlice = createSlice({
  name: "instructions",
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      state.notifications = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInstructions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInstructions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })

      .addCase(getInstructions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getNotifications.pending, (state) => {
        state.notifLoading = true;
        state.error = null;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifLoading = false;
        state.error = null;
        state.notifications = action.payload;
      })

      .addCase(getNotifications.rejected, (state, { payload }) => {
        state.notifLoading = false;
        state.error = payload;
      });
  },
});

export const { setNotifications } = instructionsSlice.actions;
export const selectLoading = (state) => state.instructions.notifLoading;
export const selectLoadingError = (state) => state.instructions.error;
export const selectInstructions = (state) => state.instructions.data;
export const selectNotifications = (state) => state.instructions.notifications;

export default instructionsSlice.reducer;
