import styled from "styled-components";
import { theme } from "styles/Theme";

export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 116px;
  font-weight: 840;
  text-transform: uppercase;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 16px;
  min-width: 400px;
`;

export const AddressName = styled.h1`
  color: ${theme.colors.primary};
  font-size: 42px;
  font-weight: 840;
  text-transform: uppercase;
`;

export const Line = styled.hr`
  height: 2px;
  background: ${theme.colors.primary};
`;

export const PhoneNumber = styled.a`
  color: ${theme.colors.primary};
  font-size: 23px;

  color: #262757;
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 25.3px */
`;

export const Email = styled.a`
  color: ${theme.colors.primary};
  font-size: 23px;

  color: #262757;
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 25.3px */
`;

export const WorkingDays = styled.p`
  color: ${theme.colors.primary};
  font-size: 23px;
`;

export const RowContainer = styled.div`
  padding: 64px 0 120px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 32px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 54px;
  padding-bottom: 130px;
`;
