import { createSlice } from "@reduxjs/toolkit";
import { getContact } from "./contactActions";

const initialState = {
  loading: false,
  error: null,
  contact: [],
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContact.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContact.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.contact = payload;
      })
      .addCase(getContact.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.contact.loading;
export const selectLoadingError = (state) => state.contact.error;
export const selectContact = (state) => state.contact.contact;

export default contactSlice.reducer;
