import { theme } from "styles/Theme";

const { colors, borderRadius, spacing, typography } = theme;

export const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    fontSize: "40px !important",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.secondary : colors.white,
    color: state.isSelected ? colors.white : colors.darkText,
    padding: spacing.small,
    cursor: "pointer",
    borderBottom: "solid 1px rgba(38, 39, 87, 0.40)",

    "&:last-child": {
      borderBottom: "none",
    },

    "&:hover": {
      backgroundColor: colors.secondary,
      color: colors.white,
    },
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: borderRadius.large,
    boxShadow: "none",
    border: "2px solid #262757",
    "&:hover": {
      borderColor: colors.primary,
      maxWidth: "100%",
    },
    // marginBottom: spacing.small,
    minHeight: 60,
  }),
  input: (provided) => ({
    ...provided,
    color: colors.darkText,
    fontSize: typography.fontSize.medium,
    fontFamily: typography.fontFamily.body,
    fontWeight: typography.fontWeight.regular,
    lineHeight: typography.lineHeight.medium,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.inputPlaceholder,

    fontSize: 13.5,
    fontFamily: typography.fontFamily.body,
    fontWeight: typography.fontWeight.regular,
    lineHeight: typography.lineHeight.medium,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: colors.white,
    borderRadius: borderRadius.medium,
    marginTop: 0,
    zIndex: 3,

    // border: `1px solid ${colors.gray}`,
    // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    // zIndex: 2,
    // maxHeight: "50px",
  }),

  clearIndicator: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
};
