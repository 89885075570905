import Form from "./form";
import { Container, Title } from "./SendInquiry.styles";

const SendInquiry = () => {
  return (
    <Container>
      <Title>SEND AN INQUIRY</Title>
      <Form />
    </Container>
  );
};

export default SendInquiry;
