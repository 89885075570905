import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

const createAsyncThunkWithEndpoint = (sliceName, endpoint) => {
  return createAsyncThunk(`${sliceName}/${endpoint}`, async (_, { rejectWithValue }) => {
    try {
      const { data } = await ApiClient.get(`/v1/${endpoint}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  });
};

export const getHeader = createAsyncThunkWithEndpoint("header", "headers");

export const getFooter = createAsyncThunkWithEndpoint("footer", "footers");
