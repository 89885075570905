import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

export const getInstructions = createAsyncThunk(
  "instructions/",
  async (_, { rejectWithValue }) => {
    try {
      const data = await ApiClient.get("/v1/instructions");
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const notificationFetch = createAsyncThunk(
  "notification/",
  async (notifications, { rejectWithValue }) => {
    try {
      await ApiClient.post("/v1/notifications", {
        notifications,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNotifications = createAsyncThunk(
  "notification/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get("/v1/notifications");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
