import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import Button from "components/button";
import CloseIcon from "@mui/icons-material/Close";
import { Overlay, Content, CloseButton, Title, Header, Footer } from "./Modal.styles";

const Modal = ({
  onOk,
  title,
  width,
  isOpen,
  footer,
  height,
  onClose,
  centered,
  children,
  container,
  closeIcon,
  className,
  onOkLoading,
  overlayClassName,
  component: Component = "div",
}) => {
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const renderModal = () => {
    return (
      <>
        <Overlay className={overlayClassName} onClick={handleOverlayClick}>
          <Content className={className} $centered={centered} width={width} height={height}>
            <Header>
              {title && <Title>{title}</Title>}
              <CloseButton onClick={onClose}>
                {closeIcon ? closeIcon : <CloseIcon />}
              </CloseButton>
            </Header>
            {children}
            {footer && (
              <Footer>
                <Button ghost onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={onOk} loading={onOkLoading}>
                  Submit
                </Button>
              </Footer>
            )}
          </Content>
        </Overlay>
      </>
    );
  };

  return isOpen
    ? createPortal(<Component>{renderModal()}</Component>, container || document.body)
    : null;
};
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  $centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  footer: PropTypes.bool,
};

Modal.defaultProps = {
  $centered: false,
  className: "",
  overlayClassName: "",
};

export default Modal;
