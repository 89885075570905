import styled, { css } from "styled-components";
import { theme } from "styles/Theme";

const { spacing, typography, colors, borderRadius } = theme;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.small};
  width: 100%;
`;

export const TextAreaLabel = styled.label`
  margin-bottom: ${spacing.xsmall};
  font-size: ${typography.fontSize.formLabel};
  font-family: ${typography.fontFamily.heading};
  color: ${colors.darkGrayText};
`;

export const TextAreaField = styled.textarea`
  height: 100%;
  width: 100%;
  min-height: 140px;
  padding: 29px;
  border-radius: ${borderRadius.medium};
  border: 1px solid ${colors.gray};
  transition: border-color 0.2s;
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */

  color: ${colors.text};
  resize: ${({ $resizeVertical }) => (!$resizeVertical ? "vertical" : "")};
  resize: ${({ $resizeHorizontal }) => (!$resizeHorizontal ? "horizontal" : "")};

  &::placeholder {
    color: #262757;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 141.667% */
  }

  &:focus {
    outline: none;
    border-color: #3f51b5;
    box-shadow: 0px 0px 3px 1px rgba(63, 81, 181, 0.1);
  }

  ${({ variant }) =>
    variant === "outlined" &&
    css`
      border: 1px solid #ccc;
      padding: 29px;
    `}

  ${({ maxLength, minLength }) =>
    css`
      max-length: ${maxLength || "none"};
      min-length: ${minLength || "none"};
    `}

  &::-webkit-scrollbar {
    width: 8px;
    margin-top: 30px !important;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(152, 145, 168, 0.37);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(107, 107, 107, 0.514);
    border-radius: 10px;
  }
`;
