import React, { useEffect, useRef, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  InputWrapper,
  InputLabel,
  InputFieldWithIcons,
  InputField,
  InputPrefix,
  InputSuffix,
  DeleteButton,
  DisabledMessage,
  ErrorMessage,
} from "./Input.styles";

const Input = forwardRef(
  (
    {
      label,
      labelLeft,
      width,
      onChange,
      defaultValue,
      type,
      prefix,
      suffix,
      placeholder,
      onDelete,
      deleteIcon,
      disabled,
      disabledMessage,
      height,
      $isValid,
      errorMessage,
      noBordered,
      secondaryBorder,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(defaultValue || "");
    const [isInteracting, setIsInteracting] = useState(false);

    useEffect(() => {
      setInputValue(defaultValue || "");
    }, [defaultValue]);

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
          setIsInteracting(false);
        }
      };

      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);

    const handleChange = (event) => {
      setInputValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    };

    const handleDelete = () => {
      setInputValue("");
      if (onDelete) {
        onDelete();
      }
    };

    const handleFocus = () => {
      setIsInteracting(true);
    };

    const handleBlur = () => {
      setIsInteracting(false);
    };

    const isDisabled = isInteracting && !onChange && disabled;
    const isInvalid = !$isValid && !isDisabled;

    return (
      <InputWrapper style={{ width }} labelLeft={labelLeft} $isInvalid={isInvalid}>
        {label && <InputLabel>{label}</InputLabel>}
        <InputFieldWithIcons>
          <InputField
            ref={ref} // Forward the ref to the underlying input
            value={inputValue}
            placeholder={placeholder}
            type={type}
            step="any"
            onChange={isDisabled ? null : handleChange}
            prefix={prefix}
            suffix={suffix}
            $deleteIcon={deleteIcon}
            disabled={isDisabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            $customHeight={height}
            $isValid={$isValid}
            $noBordered={noBordered}
            $secondaryBorder={secondaryBorder}
            {...props}
          />
          {prefix && <InputPrefix>{prefix}</InputPrefix>}
          {inputValue && deleteIcon && (
            <DeleteButton onClick={handleDelete} withSuffix={!!suffix}>
              <CancelIcon fontSize="small" color="action" />
            </DeleteButton>
          )}
          {suffix && <InputSuffix>{suffix}</InputSuffix>}
        </InputFieldWithIcons>
        {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {isDisabled && <DisabledMessage>{disabledMessage}</DisabledMessage>}
      </InputWrapper>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  labelLeft: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  placeholder: PropTypes.string,
  onDelete: PropTypes.func,
  deleteIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  $isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  deleteIcon: false,
  disabled: false,
  disabledMessage: "Input cannot be changed",
  $isValid: true,
  errorMessage: "",
};

export default Input;
