import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleApiResponse, handleApiError } from "api/apiUtils";
import ApiClient from "api/axiosClient";

export const uploadPhoto = createAsyncThunk(
  "photo/uploadPhoto",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post("/v1/user/photo/", formData, {
        "Content-Type": "multipart/form-data",
      });
      return handleApiResponse(response, "Error uploading photo", true);
    } catch (error) {
      return handleApiError(error, rejectWithValue);
    }
  }
);

const photoSlice = createSlice({
  name: "photo",
  initialState: {
    uploadedPhotoUrl: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadPhoto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadPhoto.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadedPhotoUrl = action.payload.url;
      })
      .addCase(uploadPhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error uploading photo";
      });
  },
});

export const selectPhotoUrl = (state) => state.photo.uploadedPhotoUrl;
export const selectPhotoLoading = (state) => state.photo.loading;
export const selectPhotoError = (state) => state.photo.error;

export default photoSlice.reducer;
