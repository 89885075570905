import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInstructions } from "features/instructions/instructionsActions";
import { selectInstructions } from "features/instructions/instructionsSlice";
import {
  Container,
  RowContainer,
  Wrapper,
  ImageWrapper,
  Image,
  DownloadAction,
  Description,
} from "./Download.styles";

const Download = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectInstructions);

  useEffect(() => {
    dispatch(getInstructions());
  }, [dispatch]);

  const handleOpenPdf = (pdf_file) => {
    window.open(pdf_file, "_blank");
  };

  return (
    <Container>
      <RowContainer>
        {data?.map((item, index) => (
          <Wrapper key={index} onClick={() => handleOpenPdf(item.pdf_file)}>
            <ImageWrapper>
              <Image src={item.url} />
            </ImageWrapper>
            <DownloadAction>Download</DownloadAction>
          </Wrapper>
        ))}
      </RowContainer>

      <Description>
        We give checklists with instructions on how to register on Chinese marketplaces
      </Description>
    </Container>
  );
};

export default Download;
