import styled, { css } from "styled-components";

//////////////////////////////////////
export const Title = styled.h3`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
`;

export const Description = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  ${(props) =>
    props.$hasBorder &&
    css`
      padding-left: 13px;
      border-left: 1px solid #58b5e7;
    `}
`;
//////////////////////////////////////

export const Flag = styled.img`
  width: 49px;
`;

export const DataType = styled.div`
  padding: 12px;
  width: 380px;
  height: 80px;
  border: 3px solid ${(props) => (props.$active ? "#58B5E7" : "transparent")};
  display: flex;
  gap: 10px;
  background-color: white;
  align-items: center;
  justify-content: start;
  border-radius: 16px;
  box-shadow: 0px 4px 9px 0px rgba(44, 104, 135, 0.1),
    0px 16px 16px 0px rgba(44, 104, 135, 0.09), 0px 35px 21px 0px rgba(44, 104, 135, 0.05),
    0px 63px 25px 0px rgba(44, 104, 135, 0.01), 0px 98px 27px 0px rgba(44, 104, 135, 0);

  color: #262757;
  font-family: Lexend;
  font-size: 26px;
  line-height: 21px;

  cursor: pointer;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const AddressesRow = styled.div`
  display: flex;
  gap: 61px;
  align-items: center;
`;

export const AddressContainer = styled.div`
  min-height: 474px;
  padding: 43px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(64, 132, 165, 0.1),
    0px 21px 21px 0px rgba(64, 132, 165, 0.09), 0px 48px 29px 0px rgba(64, 132, 165, 0.05),
    0px 85px 34px 0px rgba(64, 132, 165, 0.01), 0px 132px 37px 0px rgba(64, 132, 165, 0);
`;

export const InfoContainer = styled.div`
  width: 314px;
  height: 116px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  background: #e3f5ff;
  display: flex;
  gap: 21px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    color: var(--Navy-blue, #262757);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%; /* 24px */
  }
`;

export const Plane = styled.img`
  width: 79px;
  height: 79px;
`;

export const Ship = styled.img`
  width: 68px;
  height: 82px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: start;
`;
