import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const StyledTitle = styled.h1`
  color: ${theme.colors.primary};
  font-family: "Kyiv*Type Sans", fallbackFont, sans-serif;
  font-size: 54px;
  font-weight: 840;
  margin: 0;

  @font-face {
    font-family: "KyivTypeSans";
    src: local("KyivTypeSans"), url(./fonts/KyivTypeSans.woff) format("woff");
    font-weight: normal;
    font-style: normal;
  }
`;

export const Description = styled.p`
  color: ${theme.colors.primary};
  font-size: 24px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
`;

export const StyledImg = styled.img`
  width: 508px;
  height: auto;

  @media (max-width: 768px) {
    width: 300px;
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 815px;
`;
