import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "hooks/useDebounce";
import Input from "components/input";
import Button from "components/button";
import Modal from "components/modal";
import Radio from "components/radio";
import {
  Image,
  ColumnContainer,
  StyledP,
  Row,
  ModalColumn,
  RowTerm,
  Conditions,
  SignUpText,
  SignInUnderLine,
  RowDiv,
  ErrorCol,
} from "./Register.styles";
import { registerUser, mailConfirm } from "features/auth/authActions";
import { setIsLoginPage, selectRegError } from "features/auth/authSlice";
import { RowContainer } from "../login/Login.styles";
import { Description, Title, Wrapper } from "../../calculator/Calculator.styles";
import Tooltip from "../../../components/tooltip/Tooltip";
import error from "./error.png";

const Register = () => {
  const [name, setName] = useState({ value: "", isValid: true, errorMessage: "" });
  const [username, setUsername] = useState({ value: "", isValid: true, errorMessage: "" });
  const [email, setEmail] = useState({ value: "", isValid: true, errorMessage: "" });
  const [password, setPassword] = useState({ value: "", isValid: true, errorMessage: "" });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    isValid: true,
    errorMessage: "",
  });
  const [codeModalVisible, setCodeModalVisible] = useState(false);
  const [code, setCode] = useState("");
  const [regErrorModalOpen, setRegErrorModalOpen] = useState(false);

  const regError = useSelector(selectRegError);

  useEffect(() => {
    if (regError !== null) {
      setRegErrorModalOpen(true);
    }
  }, [regError]);

  const handleCloseRegErrorModal = () => {
    setRegErrorModalOpen(false);
  };

  const dispatch = useDispatch();

  const debouncedCode = useDebounce(code, 2000);

  useEffect(() => {
    if (debouncedCode && codeModalVisible) {
      dispatch(mailConfirm({ email: email.value, confirmation_code: debouncedCode }));
      setCodeModalVisible(false);
      setCode("");
    }
  }, [debouncedCode, dispatch, email.value, codeModalVisible]);

  const handleCloseCodeModal = () => {
    setCodeModalVisible(false);
  };

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const validateInputs = () => {
    const isNameValid = name.value.trim() !== "";
    const isUsernameValid = username.value.trim() !== "";
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value);
    const isPasswordValid =
      password.value.length >= 8 &&
      /[A-Z]/.test(password.value) &&
      /[a-z]/.test(password.value) &&
      /\d/.test(password.value) &&
      /[!@?#$%^&*-]/.test(password.value) &&
      password.value === confirmPassword.value;

    setName({
      ...name,
      isValid: isNameValid,
      errorMessage: isNameValid ? "" : "Name is required",
    });

    setUsername({
      ...username,
      isValid: isUsernameValid,
      errorMessage: isUsernameValid ? "" : "Username is required",
    });

    setEmail({
      ...email,
      isValid: isEmailValid,
      errorMessage: isEmailValid ? "" : "Invalid email address",
    });

    setPassword({
      ...password,
      isValid: isPasswordValid,
      errorMessage: isPasswordValid
        ? ""
        : "Password must meet the specified criteria and match the confirm password",
    });

    setConfirmPassword({
      ...confirmPassword,
      isValid: isPasswordValid,
      errorMessage: isPasswordValid
        ? ""
        : "Password must meet the specified criteria and match the confirm password",
    });

    return isNameValid && isUsernameValid && isEmailValid && isPasswordValid;
  };

  const handleSubmit = () => {
    const isFormValid = validateInputs();

    if (isFormValid) {
      dispatch(
        registerUser({
          first_name: name.value,
          last_name: username.value,
          email: email.value,
          password: password.value,
        })
      );
      regError === null && setCodeModalVisible(true);
    }
  };

  const handleChangeToLogin = () => {
    dispatch(setIsLoginPage("login"));
  };

  return (
    <RowContainer>
      <Image>
        <h1>Sign up</h1>
      </Image>

      <ColumnContainer>
        <RowContainer>
          <Input
            label="Name"
            value={name.value}
            onChange={(e) => setName({ ...name, value: e.target.value })}
            required
            $isValid={name.isValid}
            errorMessage={name.errorMessage}
          />
          <Input
            label="Username"
            value={username.value}
            required
            onChange={(e) => setUsername({ ...username, value: e.target.value })}
            $isValid={username.isValid}
            errorMessage={username.errorMessage}
          />
        </RowContainer>
        <RowContainer>
          <Input
            label="Email"
            value={email.value}
            onChange={(e) => setEmail({ ...email, value: e.target.value })}
            required
            $isValid={email.isValid}
            errorMessage={email.errorMessage}
          />
        </RowContainer>
        <Tooltip
          text="
          - Minimum Length: 8 characters
- At least 1 uppercase letter (A-Z)
- At least 1 lowercase letter (a-z)
- At least 1 digit (0-9)
- At least 1 special character (!@?#$%^&*-)"
        >
          <RowContainer>
            <Input
              label="Password"
              type="password"
              value={password.value}
              onChange={(e) => setPassword({ ...password, value: e.target.value })}
              required
              placeholder="8+ characters"
              $isValid={password.isValid}
              errorMessage={password.errorMessage}
            />
            <Input
              label="Confirm password"
              type="password"
              value={confirmPassword.value}
              onChange={(e) =>
                setConfirmPassword({ ...confirmPassword, value: e.target.value })
              }
              required
              placeholder="8+ characters"
              $isValid={confirmPassword.isValid}
              errorMessage={confirmPassword.errorMessage}
            />
          </RowContainer>
        </Tooltip>

        <RowTerm>
          <Radio label="" name="radioGroup" />
          <p>I accept the terms and</p> <Conditions>conditions</Conditions> <p>and</p>
          <Conditions>privacy statement</Conditions>
        </RowTerm>

        <Button height={53} onClick={handleSubmit}>
          Create Account
        </Button>

        {regError === null ? (
          <Modal isOpen={codeModalVisible} onClose={handleCloseCodeModal} width="647px">
            <ModalColumn>
              <Row>
                <Wrapper>
                  <Title>Confirm your email</Title>
                </Wrapper>
                <Wrapper $hasBorder={true}>
                  <Description>
                    We have sent you an email with a confirmation code, enter it below
                  </Description>
                </Wrapper>
              </Row>

              <h4>Confirmation code</h4>
              <Input width={270} onChange={handleCodeChange} value={code} />
              <StyledP>The code will arrive within 5 minutes to your email address</StyledP>
            </ModalColumn>
          </Modal>
        ) : (
          <Modal isOpen={regErrorModalOpen} onClose={handleCloseRegErrorModal} width="613px">
            <ErrorCol>
              <h1>Account already exists with that email address</h1>
              <p>Please use a different email address or login to the account</p>
              <img src={error} alt="error" />
            </ErrorCol>
          </Modal>
        )}

        <RowDiv onClick={handleChangeToLogin}>
          <SignUpText>Already have an account? </SignUpText>
          <SignInUnderLine> Sign in</SignInUnderLine>
        </RowDiv>
      </ColumnContainer>
    </RowContainer>
  );
};

export default Register;
