import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAuthorized, setIsLoginPage } from "features/auth/authSlice";
import { selectHeader } from "features/navigation/navigationSlice";
import { getHeader } from "features/navigation/navigationActions";
import { getUserInfo } from "features/auth/authActions";

import MenuIcon from "@mui/icons-material/Menu";
import AvatarMenu from "./AvatarMenu";
import Button from "../button/Button";
import logo from "assets/logo.png";
import logoSmaller from "assets/logo-xs.png";
import {
  Logo,
  LogoSmaller,
  MenuIconWrapper,
  NavbarContainer,
  NavLink,
  NavLinks,
} from "./Navbar.style";

const Navbar = ({ marginBottom }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isLoggedIn = useSelector(isAuthorized);
  const navItems = useSelector(selectHeader);

  const sortedMenuItems = [...navItems].sort((a, b) => a.order - b.order);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHeader());
    isLoggedIn && dispatch(getUserInfo());
  }, [dispatch, isLoggedIn]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = (link) => {
    dispatch(setIsLoginPage("login"));
    navigate(link);
    window.scrollTo(0, 0);
    setIsOpen(false);
  };

  return (
    <NavbarContainer $marginBottom={marginBottom}>
      <Logo src={logo} alt="Logo" onClick={() => handleNavigate("/home")} />
      <LogoSmaller src={logoSmaller} alt="Logo" onClick={() => handleNavigate("/home")} />
      <NavLinks $isOpen={isOpen}>
        {sortedMenuItems?.map((item) => (
          <NavLink key={item.link} onClick={() => handleNavigate(item.link)}>
            {item.name}
          </NavLink>
        ))}
        {!isLoggedIn ? (
          <Button onClick={() => handleNavigate("/login")} variant="link">
            Sign In
          </Button>
        ) : (
          <AvatarMenu />
        )}
      </NavLinks>
      <MenuIconWrapper onClick={toggleMenu}>
        <MenuIcon />
      </MenuIconWrapper>
    </NavbarContainer>
  );
};

export default Navbar;
