import { createSlice } from "@reduxjs/toolkit";
import { getRecipients } from "./recipientsActions";

const initialState = {
  loading: false,
  error: null,
  recipients: [],
};

const recipientsSlice = createSlice({
  name: "recipients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecipients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRecipients.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.recipients = payload;
      })
      .addCase(getRecipients.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.recipients.loading;
export const selectLoadingError = (state) => state.recipients.error;
export const selectRecipients = (state) => state.recipients.recipients;

export default recipientsSlice.reducer;
