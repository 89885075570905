import { useSelector } from "react-redux";
import { selectIsLoginPage } from "features/auth/authSlice.js";
import Login from "./login";
import Register from "./register";
import RegStart from "./register/RegStart";
import Forgot from "./forgot";

const Signin = () => {
  const isLoginPage = useSelector(selectIsLoginPage);

  switch (isLoginPage) {
    case "login":
      return <Login />;
    case "regStart":
      return <RegStart />;
    case "signUp":
      return <Register />;
    case "forgot":
      return <Forgot />;
    default:
      return <Login />;
  }
};

export default Signin;
