import { createSlice, createSelector } from "@reduxjs/toolkit";
import { registerUser, userLogin, userLogout, getUserInfo } from "./authActions";
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
} from "../../api/TokenService";

const initialState = {
  loading: false,
  userInfo: {}, // for user object
  accessToken: getAccessToken() || null,
  refreshToken: getRefreshToken() || null,
  error: null,
  success: false, // for monitoring the registration process.
  isLoginPage: "login",
  regError: null,
  logError: null,
  email: { value: "", isValid: true, errorMessage: "" },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsLoginPage: (state, action) => {
      state.isLoginPage = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.accessToken = payload.access_token;
        state.refreshToken = payload.refresh_token;
        state.userInfo = payload;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.logError = payload;
      })
      // logout user
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogout.fulfilled, (state, { payload }) => {
        removeAccessToken();
        removeRefreshToken();
        state.loading = false;
        state.error = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.userInfo = null;
        state.success = false;
      })
      .addCase(userLogout.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // register user
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = true; // registration successful
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.loading = false;
        state.regError = payload;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload;
      })
      .addCase(getUserInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectIsLoginPage = (state) => state.auth.isLoginPage;
export const { setIsLoginPage, setEmail } = authSlice.actions;

export const selectLoading = (state) => state.auth.loading;
export const selectLoadingError = (state) => state.auth.error;
export const selectCurrentAccessToken = (state) => state.auth.accessToken;
export const selectUserInfo = (state) => state.auth.userInfo;
export const selectRegError = (state) => state.auth.regError;
export const selectLogError = (state) => state.auth.logError;
export const selectEmail = (state) => state.auth.email;

export const isAuthorized = createSelector(selectCurrentAccessToken, (accessToken) => {
  return !!accessToken;
});

export default authSlice.reducer;
