import { useDispatch, useSelector } from "react-redux";
import { selectTeam } from "features/transportation/transportationSlice";
import { getTeam } from "features/transportation/transportationActions";
import { Container, Title, Wrapper, Description, Quote, Image } from "./OurTeam.styles";
import team from "assets/team.png";
import { useEffect } from "react";

const OutTeam = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectTeam);

  useEffect(() => {
    dispatch(getTeam());
  }, []);

  return (
    <Container>
      <Wrapper>
        <Title>{data?.title}</Title>
        <Description>{data.description}</Description>
        <Quote>{data?.text}</Quote>
      </Wrapper>

      <Image src={team} alt="team" />
    </Container>
  );
};

export default OutTeam;
