import styled from "styled-components";
import { theme } from "styles/Theme";

export const Container = styled.div`
  /* padding: 80px;

  @media (max-width: 768px) {
    padding: 22px;
  } */
`;

export const Title = styled.h1`
  color: ${theme.colors.primary};
  font-size: 102px;
  font-style: normal;
  font-weight: 840;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 55px;
  }
`;

export const Questions = styled.span`
  color: white !important;
  background-color: ${theme.colors.blue};
`;

export const AccordionWrapper = styled.div`
  padding: 70px 0 114px 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  align-items: end;
`;
