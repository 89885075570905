import DatePicker from "react-datepicker";
import { theme } from "styles/Theme";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";

const { spacing, typography, colors, borderRadius } = theme;

export const DateRangeWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledDatePicker = styled(DatePicker)`
  min-height: 63px;
  padding: ${spacing.small};
  border: 2px solid ${colors.primary};
  border-radius: ${borderRadius.large};
  color: ${colors.primary};
  background-color: ${colors.white};
  outline: none;
  transition: all 0.2s ease-in-out;
  width: 100%;

  font-family: Lexend;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;

  &::placeholder {
    color: ${colors.inputPlaceholder};
  }

  &:hover,
  &:focus {
    border-color: ${colors.primary};
    box-shadow: 0px 0px 5px 0px rgba(0, 119, 255, 0.3);
  }

  &:focus {
    outline: none;
    border: 1px solid ${colors.primary};
  }
`;

export const DateIcon = styled.span`
  position: absolute;
  z-index: 1;
  top: 6px;
  left: 2px;
  /* left: ${spacing.xsmall}; */
  /* left: 50%; */
  /* transform: translate(0, 5px); */
  & svg {
    color: ${colors.primary};
  }
`;

export const Icon = styled.img`
  width: 43px;
  height: 43px;

  position: absolute;
  right: 29px;
  top: 10px;
  z-index: 999999;
`;

export const Div = styled.div`
  position: relative;
  min-width: 240px;
`;
