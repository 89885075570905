import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Column = styled.div`
  width: 100%;
`;

export const Title = styled.h3`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 41px;
`;

export const Row = styled.div`
  display: flex;
  gap: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;

  margin-top: ${(props) => props.$marginTop};

  p {
    color: #262757;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
  }
`;
