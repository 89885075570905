import styled, { css } from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 28px;
  margin-bottom: ${(props) => props.$bottom};
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => (props.$gap ? `${props.$gap}px` : "0")};
`;

export const ShipmentType = styled.img`
  width: 130px;
  height: 119px;
`;

export const Title = styled.h3`
  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
`;

export const Description = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
`;

export const Wrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 35px;

  ${(props) =>
    props.$hasBorder &&
    css`
      padding-left: 13px;
      border-left: 1px solid #58b5e7;
    `}
`;

export const Duration = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 116.667% */
  margin-top: 13px;
`;

export const StyledSpan = styled.span`
  color: #262757;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 131.25% */

  display: flex;
  gap: 4px;
  margin-top: 32px;
  margin-bottom: 26px;
`;

export const Result = styled.div`
  display: flex;
  width: 223px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
  border: 3px solid #58b5e7;

  color: #58b5e7;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
`;
