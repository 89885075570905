import styled from "styled-components";

export const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RadioInput = styled.input`
  appearance: none;
  width: 28px;
  height: 28px;
  border: 2px solid #262757;
  border-radius: 50%;
  margin-right: 8px;
  outline: none;

  &:checked {
    background-color: #262757;
  }
`;

export const RadioText = styled.span`
  font-size: 14px;
`;
