import { createSlice } from "@reduxjs/toolkit";
import { getCooperationLogos } from "./cooperationActions";

const initialState = {
  loading: false,
  error: null,
  logos: [],
};

const cooperationSlice = createSlice({
  name: "cooperation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCooperationLogos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCooperationLogos.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.logos = payload;
      })
      .addCase(getCooperationLogos.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.cooperation.loading;
export const selectLoadingError = (state) => state.cooperation.error;
export const selectCooperation = (state) => state.cooperation.logos;

export default cooperationSlice.reducer;
