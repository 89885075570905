import styled from "styled-components";
import { theme } from "styles/Theme";

export const Container = styled.div`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  padding: 85px 120px;
  gap: 60px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
    padding: 32px;
  }
`;

export const Title = styled.h1`
  color: ${theme.colors.white};
  width: 100%;
  font-family: Kyiv * Type Sans;
  font-size: 102px;
  font-style: normal;
  font-weight: 840;
  text-transform: uppercase;
`;
