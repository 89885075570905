import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  width: 100%;

  color: #262757;
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 105% */
`;

export const HeaderRow = styled.div`
  display: flex;
  font-size: 20px;
  width: 100%;
  align-items: center;
  justify-content: centerl;
  height: 67px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(64, 132, 165, 0.1),
    0px 21px 21px 0px rgba(64, 132, 165, 0.09), 0px 48px 29px 0px rgba(64, 132, 165, 0.05),
    0px 85px 34px 0px rgba(64, 132, 165, 0.01), 0px 132px 37px 0px rgba(64, 132, 165, 0);
`;

export const HeaderCell = styled.div`
  flex: 1;
  padding: 23px 36px;
  text-align: center;
  position: relative;
  width: 100%;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 21px;
    border-radius: 16px;
    background: #c1e9ff;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const Body = styled.div`
  overflow-y: auto;

  border-radius: 16px;
  font-size: 18px;
  padding: 19px;
  height: 356px;
  background: #fff;
  box-shadow: 0px 5px 12px 0px rgba(64, 132, 165, 0.1),
    0px 21px 21px 0px rgba(64, 132, 165, 0.09), 0px 48px 29px 0px rgba(64, 132, 165, 0.05),
    0px 85px 34px 0px rgba(64, 132, 165, 0.01), 0px 132px 37px 0px rgba(64, 132, 165, 0);
`;

export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 54px;
  /* padding-top: 4px; */
  /* align-items: center; */
`;

export const DataCell = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #acc9d9;
`;
