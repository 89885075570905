import styled from "styled-components";
import loginPhotoWGift from "assets/login/login-photo-w-gift.png";
import { theme } from "styles/Theme";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  justify-content: space-between;
  padding-bottom: 32px;
`;

export const StyleForLogin = styled.div`
  width: 100%;
  padding-left: 45px;
  background-image: url(${loginPhotoWGift});
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 527px;

  h1 {
    margin-top: -35px;
    color: #fff;
    font-size: 116px;
    font-style: normal;
    font-weight: 840;
    text-transform: uppercase;
  }
`;

export const ColumnContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SignUpText = styled.p`
  color: #6d6562;
  font-family: Lexend;
  font-weight: 300;
  text-decoration-line: underline;
  align-self: end;
  cursor: pointer;
`;

export const Label = styled.label`
  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 34.5px; /* 172.5% */
`;

export const ForgotLink = styled.p`
  color: #262757;
  font-family: Lexend;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 34.5px; /* 181.579% */
  text-decoration-line: underline;
  text-align: right;
  cursor: pointer;
`;

export const LabelsInRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  hr {
    width: 77px;
    height: 1px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

  hr {
    width: 77px;
    height: 1px;
  }
`;

export const StyledSignInBtn = styled.button`
  display: flex;
  width: 410px;
  height: 75px;
  padding: 18px 44px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 14px;

  border-radius: 16px;
  border: 1px solid #ede8e8;
  background: #fff;
  box-shadow: 0px 10px 3px 0px rgba(0, 0, 0, 0.25);

  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 87.5% */
`;

export const Icon = styled.img`
  width: 29px;
`;

export const StyledP = styled.p`
  color: #6d6562;
  text-align: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Remember = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  div {
    color: ${theme.colors.primary} !important;
  }

  p {
    color: #6d6562;
    text-align: center;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
`;

export const ModalRow = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;
