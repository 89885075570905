import { createSlice } from "@reduxjs/toolkit";
import { getWhyHaystreamHome, getWhyHaystreamAbout } from "./whyHaystreamActions";

const initialState = {
  loading: false,
  error: null,
  home: [],
  about: [],
};

const whyHaystreamSlice = createSlice({
  name: "whyHaystream",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWhyHaystreamHome.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWhyHaystreamHome.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.home = payload;
      })
      .addCase(getWhyHaystreamHome.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      .addCase(getWhyHaystreamAbout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWhyHaystreamAbout.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.about = payload;
      })
      .addCase(getWhyHaystreamAbout.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoading = (state) => state.whyHaystream.loading;
export const selectLoadingError = (state) => state.whyHaystream.error;
export const selectHomeWhyHaystream = (state) => state.whyHaystream.home;
export const selectAboutWhyHaystream = (state) => state.whyHaystream.about;

export default whyHaystreamSlice.reducer;
