import { createSlice } from "@reduxjs/toolkit";
import { getHeader, getFooter } from "./navigationActions";
import info from "assets/personalNavbar/personalInfo.png";
import recipients from "assets/personalNavbar/Recipients.png";
import notifications from "assets/personalNavbar/notifications.png";
import transactions from "assets/personalNavbar/transactions.png";
import settings from "assets/personalNavbar/settings.png";
import orders from "assets/personalNavbar/orders.png";

const initialState = {
  loading: false,
  error: null,
  header: [],
  footer: [],
  personalNavItems: [
    { id: 1, label: "Orders", active: false, src: orders },
    { id: 2, label: "Personal Info", active: false, src: info },
    { id: 3, label: "Recipients", active: false, src: recipients },
    { id: 4, label: "Notifications", active: false, src: notifications },
    { id: 5, label: "Transactions", active: false, src: transactions },
    { id: 6, label: "Settings", active: false, src: settings },
  ],
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setNavItems: (state, action) => {
      const itemId = action.payload;
      state.personalNavItems = state.personalNavItems.map((item) => ({
        ...item,
        active: item.id === itemId,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHeader.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getHeader.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.header = payload;
      })
      .addCase(getHeader.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getFooter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFooter.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.footer = payload;
      })
      .addCase(getFooter.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const { setNavItems } = navigationSlice.actions;
export const selectLoading = (state) => state.navigation.loading;
export const selectLoadingError = (state) => state.navigation.error;
export const selectHeader = (state) => state.navigation.header;
export const selectFooter = (state) => state.navigation.footer;
export const selectPersonalNavItems = (state) => state.navigation.personalNavItems;

export default navigationSlice.reducer;
