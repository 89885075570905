import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";
import { setAccessToken, setRefreshToken } from "../../api/TokenService";

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ first_name, last_name, email, password }, { rejectWithValue }) => {
    try {
      await ApiClient.post("/v1/user/register", {
        first_name,
        last_name,
        email,
        password,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data } = await ApiClient.post("/v1/user/login", { email, password });
      if (data?.access_token && data.refresh_token) {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgot",
  async (email, { rejectWithValue }) => {
    try {
      await ApiClient.post("/v1/user/password/forgot", {
        email: email,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const setNewPassword = createAsyncThunk(
  "auth/forgot/new",
  async ({ email, password, code }, { rejectWithValue }) => {
    try {
      await ApiClient.post("/v1/user/password/new", {
        key: code,
        email: email,
        password: password,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk("auth/logout", async (_, { rejectWithValue }) => {
  try {
    const { data } = await ApiClient.get("/v1/user/logout");
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const mailConfirm = createAsyncThunk(
  "mail/confirm",
  async ({ email, confirmation_code }, { rejectWithValue }) => {
    try {
      const { data } = await ApiClient.post("/v1/user/register/confirmation", {
        email,
        confirmation_code,
      });
      if (data?.access_token && data.refresh_token) {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "auth/user/info",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ApiClient.get("/v1/user/info/");
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "auth/user/delete",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ApiClient.del("/v1/user/delete/user");
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "user/info/update",
  async (
    {
      first_name,
      last_name,
      email,
      birthday,
      sex,
      phone_number,
      receiver_address,
      username,
      passport,
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await ApiClient.patch("/v1/user/info/", {
        first_name,
        last_name,
        email,
        birthday,
        sex,
        phone_number,
        receiver_address,
        username,
        passport,
      });
      if (data?.access_token && data.refresh_token) {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/password/update",
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      await ApiClient.post("/v1/user/password/reset", {
        old_password: oldPassword.value,
        new_password: newPassword.value,
      });
      return { success: true };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
