import React, { useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { TextAreaField, TextAreaLabel, TextAreaWrapper } from "./TextArea.styles";

const TextArea = forwardRef(
  (
    {
      label,
      value,
      onChange,
      placeholder,
      resizeVertical,
      resizeHorizontal,
      minLength,
      maxLength,
      ...props
    },
    ref
  ) => {
    const [textAreaValue, setTextAreaValue] = useState(value || "");

    useEffect(() => {
      setTextAreaValue(value || "");
    }, [value]);

    const handleChange = (event) => {
      setTextAreaValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    };

    return (
      <TextAreaWrapper>
        {label && <TextAreaLabel>{label}</TextAreaLabel>}
        <TextAreaField
          ref={ref}
          value={textAreaValue}
          onChange={handleChange}
          placeholder={placeholder || "Введите текст..."}
          $resizeVertical={resizeVertical}
          $resizeHorizontal={resizeHorizontal}
          minLength={minLength}
          maxLength={maxLength}
          {...props}
        />
      </TextAreaWrapper>
    );
  }
);

TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  resizeVertical: PropTypes.bool,
  resizeHorizontal: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};

TextArea.defaultProps = {
  label: "",
  value: "",
  onChange: () => {},
  placeholder: "Введите текст...",
  resizeVertical: false,
  resizeHorizontal: true,
};

export default TextArea;
