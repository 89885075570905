import styled from "styled-components";

export const DataTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 12px;
  padding-top: 70px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 32px;
`;

export const DataType = styled.div`
  padding: 12px;
  width: 300px;
  height: 80px;
  border: 3px solid ${(props) => (props.$active ? "#58B5E7" : "transparent")};
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0px 4px 9px 0px rgba(44, 104, 135, 0.1),
    0px 16px 16px 0px rgba(44, 104, 135, 0.09), 0px 35px 21px 0px rgba(44, 104, 135, 0.05),
    0px 63px 25px 0px rgba(44, 104, 135, 0.01), 0px 98px 27px 0px rgba(44, 104, 135, 0);

  color: #262757;
  font-family: Lexend;
  font-size: 26px;
  line-height: 21px;

  cursor: pointer;
`;
