import { useState } from "react";
import { useDispatch } from "react-redux";
import ButtonPersonal from "../components/buttonPersonal";
import { Select } from "../components/select";
import Modal from "components/modal";
import Input from "components/input";
import { deleteUser, updatePassword } from "features/auth/authActions";
import {
  InputWrapper,
  Container,
  DeleteAccount,
  LabelWrapper,
  PassWrapper,
  LanguageSelectWrapper,
  RowEnd,
  DeleteConfirmation,
  RowInModal,
  StyledText,
  ModalCol,
} from "./Settings.styles";

const Settings = () => {
  const [isDeleteAccModalVisible, setIsDeleteAccModalVisible] = useState(false);

  const [oldPassword, setOldPassword] = useState({
    value: "",
    isValid: true,
    errorMessage: "",
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    isValid: true,
    errorMessage: "",
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    value: "",
    isValid: true,
    errorMessage: "",
  });

  const dispatch = useDispatch();

  const handleCloseDeleteAccModal = () => {
    setIsDeleteAccModalVisible(false);
  };

  const handleDeleteAccount = () => {
    dispatch(deleteUser());
  };

  const validatePasswordInputs = () => {
    const isNewPasswordValid = newPassword.value.length >= 8;
    const doPasswordsMatch = newPassword.value === confirmNewPassword.value;

    setNewPassword({
      ...newPassword,
      isValid: isNewPasswordValid,
      errorMessage: isNewPasswordValid ? "" : "New password must be at least 8 characters",
    });

    setConfirmNewPassword({
      ...confirmNewPassword,
      isValid: doPasswordsMatch,
      errorMessage: doPasswordsMatch ? "" : "Passwords do not match",
    });

    return isNewPasswordValid && doPasswordsMatch;
  };

  const handleSave = () => {
    const isPasswordValid = validatePasswordInputs();

    if (isPasswordValid) {
      dispatch(updatePassword({ oldPassword, newPassword }));
      setOldPassword({ ...oldPassword, value: "" });
      setNewPassword({ ...newPassword, value: "" });
    }
  };

  const languageOptions = [{ value: "english", label: "English" }];

  return (
    <Container>
      <input
        type="text"
        style={{ position: "absolute", left: "-9999px" }}
        autoComplete="off"
      />
      <input
        type="text"
        style={{ position: "absolute", left: "-9999px" }}
        autoComplete="off"
      />

      <LabelWrapper>Language</LabelWrapper>

      <LanguageSelectWrapper>
        <Select
          placeholder="Choose a language"
          height={50}
          secondaryBorder
          options={languageOptions}
          value={languageOptions[0]}
          autoComplete="off"
        />
      </LanguageSelectWrapper>

      <PassWrapper>Change Password</PassWrapper>

      <InputWrapper>
        <Input
          label="Old password"
          type="password"
          value={oldPassword.value}
          onChange={(e) => setOldPassword({ ...oldPassword, value: e.target.value })}
          autoComplete="off"
          required
        />
        <Input
          label="New password"
          type="password"
          value={newPassword.value}
          autoComplete="off"
          onChange={(e) => setNewPassword({ ...newPassword, value: e.target.value })}
          required
          $isValid={newPassword.isValid}
          errorMessage={newPassword.errorMessage}
        />
        <Input
          label="Confirm new password"
          type="password"
          autoComplete="off"
          value={confirmNewPassword.value}
          onChange={(e) =>
            setConfirmNewPassword({ ...confirmNewPassword, value: e.target.value })
          }
          required
          $isValid={confirmNewPassword.isValid}
          errorMessage={confirmNewPassword.errorMessage}
        />
      </InputWrapper>
      <RowEnd>
        <ButtonPersonal type="cancel" width={208}>
          Cancel
        </ButtonPersonal>
        <ButtonPersonal type="submit" width={208} onClick={handleSave}>
          Save
        </ButtonPersonal>
      </RowEnd>

      <DeleteAccount onClick={() => setIsDeleteAccModalVisible(true)}>
        Delete Account
      </DeleteAccount>

      <Modal
        width={"613px"}
        onClose={handleCloseDeleteAccModal}
        isOpen={isDeleteAccModalVisible}
      >
        <ModalCol>
          <DeleteConfirmation>Do you really want to delete the account?</DeleteConfirmation>

          <RowInModal>
            <ButtonPersonal type={"cancel"} onClick={handleCloseDeleteAccModal}>
              Cancel
            </ButtonPersonal>
            <ButtonPersonal type={"submit"} onClick={handleDeleteAccount}>
              DELETE
            </ButtonPersonal>
          </RowInModal>
          <StyledText>You can always register again </StyledText>
        </ModalCol>
      </Modal>
    </Container>
  );
};

export default Settings;
