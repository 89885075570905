import React, { useState } from "react";
import { TooltipWrapper, StyledTooltip } from "./Tooltip.styles";

const Tooltip = ({ text, children }) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <TooltipWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <StyledTooltip $isVisible={isTooltipVisible}>{text}</StyledTooltip>
      {children}
    </TooltipWrapper>
  );
};

export default Tooltip;
