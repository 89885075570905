import React, { useState } from "react";
import ph_copy from "./ph_copy.png";
import {
  MyFieldItemContainer,
  MyFieldItemWrapper,
  MyFieldItemRow,
  ItemRight,
  ItemLeft,
  ItemIcon,
  Row,
} from "./MyFieldItem.styles";

const MyFieldItem = ({ data }) => {
  const [copiedRow, setCopiedRow] = useState(null);

  const handleCopyRow = (index) => {
    setCopiedRow(index);
    const textToCopy = `${data[index].label} ${data[index].value}`;
    copyTextToClipboard(textToCopy);
  };

  const handleCopyAll = () => {
    const textToCopy = data.map((item) => `${item.label} ${item.value}`).join("\n");
    copyTextToClipboard(textToCopy);
  };

  const copyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  return (
    <MyFieldItemContainer>
      <MyFieldItemRow>
        {data?.map((item, index) => (
          <MyFieldItemWrapper key={index}>
            <ItemLeft>{item.label}</ItemLeft>
            <ItemRight>{item.value}</ItemRight>
            <ItemIcon src={ph_copy} alt="copy" onClick={() => handleCopyRow(index)} />
          </MyFieldItemWrapper>
        ))}
      </MyFieldItemRow>
      <Row>
        <ItemIcon src={ph_copy} alt="copy" onClick={handleCopyAll} />
        Copy all information
      </Row>
    </MyFieldItemContainer>
  );
};

export default MyFieldItem;
