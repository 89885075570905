import styled from "styled-components";
import { theme } from "styles/Theme";

export const Container = styled.div`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  padding: 45px;

  @media (max-width: 768px) {
    padding: 32px;
  }

  p {
    color: ${theme.colors.white};

    @media (min-width: 768px) {
      max-width: 55%;
      font-size: 24px;
    }
  }
`;

export const Title = styled.h1`
  color: ${theme.colors.white};
  font-weight: 840;
  font-size: 102px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`;

export const Logo = styled.img`
  max-width: 213px;
`;

export const LogosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
`;
