import { useDispatch, useSelector } from "react-redux";
import { selectDelivery } from "features/transportation/transportationSlice";
import { getDelivery } from "features/transportation/transportationActions";
import {
  ColumnContainer,
  Description,
  RowContainer,
  StyledImg,
  StyledTitle,
} from "./Delivery.styles";
import delivery from "../../../assets/delivery.png";
import { useEffect } from "react";

const Delivery = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectDelivery);

  useEffect(() => {
    dispatch(getDelivery());
  }, []);

  return (
    <>
      <RowContainer>
        <ColumnContainer>
          <StyledTitle>{data?.title}</StyledTitle>
          <Description>{data?.description}</Description>
        </ColumnContainer>
        <StyledImg src={delivery} alt="delivery" />
      </RowContainer>
    </>
  );
};

export default Delivery;
