import React, { useState, useEffect } from "react";
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  CheckboxLabel,
} from "./Checkbox.styles";

const Checkbox = ({ label, onChange, checked, size, exclusiveGroup }) => {
  const [isChecked, setChecked] = useState(checked || false);

  useEffect(() => {
    // When the component mounts, register it with the exclusive group
    exclusiveGroup && exclusiveGroup.registerCheckbox(isChecked, setChecked);
    // Clean up the registration when the component unmounts
    return () => exclusiveGroup && exclusiveGroup.unregisterCheckbox(setChecked);
  }, [exclusiveGroup]);

  const handleCheckboxChange = () => {
    // If the checkbox is part of an exclusive group, update the other checkboxes
    if (exclusiveGroup) {
      exclusiveGroup.updateCheckboxes(isChecked, setChecked);
    } else {
      // If it's not part of an exclusive group, handle the change normally
      setChecked(!isChecked);
      onChange && onChange(!isChecked);
    }
  };

  return (
    <CheckboxContainer size={size}>
      <HiddenCheckbox checked={isChecked} onChange={handleCheckboxChange} />
      <StyledCheckbox checked={isChecked} onClick={handleCheckboxChange} size={size}>
        {isChecked && <span>&#10003;</span>}
      </StyledCheckbox>
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
};

export default Checkbox;
