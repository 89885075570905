import styled from "styled-components";
import { theme } from "../../../styles/Theme";

export const StyledTitle = styled.h1`
  color: ${theme.colors.primary};
  font-size: 102px;
  font-weight: 840;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1250px;
  align-items: center;
  padding: 12px 0;

  @media (min-width: 768px) {
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 304px;
  height: 100%;
  gap: 12px;
  padding: 12px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  min-height: 240px;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

export const Order = styled.h1`
  color: ${theme.colors.primary};
  font-size: 54px;
  font-weight: 840;
`;

export const Description = styled.p`
  color: ${theme.colors.primary};
  font-family: Lexend;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 25.3px */
`;
