import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRecipients } from "features/recipients/recipientsActions";
import { selectRecipients } from "features/recipients/recipientsSlice";
import edit from "./edit.png";
import add from "./add.png";
import {
  Container,
  Recipient,
  Row,
  Icon,
  Name,
  Items,
  AddRecipient,
} from "./Recipients.styles";

const Recipients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectRecipients);

  const handleClickNavigateAddRecipient = () => {
    navigate("/recipients/add");
  };

  const handleClickEditRecepient = (id) => {
    navigate(`/recipients/${id}`);
  };

  useEffect(() => {
    dispatch(getRecipients());
  }, [dispatch]);

  return (
    <Container>
      {data?.map((recipient, index) => (
        <Recipient key={index}>
          <Row>
            <Name>
              {recipient.name} {recipient.surname}
            </Name>
            <Icon src={edit} onClick={() => handleClickEditRecepient(recipient.id)} />
          </Row>
          <Items>{recipient.phone}</Items>
          <Items>
            {recipient.state} {recipient.city} {recipient.address}
          </Items>
          <Items>{recipient.country}</Items>
          <Items>{recipient.zip_code}</Items>
        </Recipient>
      ))}

      <AddRecipient src={add} onClick={handleClickNavigateAddRecipient} />
    </Container>
  );
};

export default Recipients;
