import { StyledDatePicker, Icon, Div } from "./DatePicker.styles";
import calendar from "./calendar.png";

const DatePickerComponent = ({
  value,
  onChange,
  shouldCloseOnSelect = true,
  isClearable = true,
  customInput,
  handleCalendarOpen,
  handleCalendarClose,
  placeholderText = "Выберите дату",
  dateFormat = "dd/MM/yyyy",
  disabled = false,

  ...props
}) => {
  const handleDateChange = (date) => {
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <Div>
      <Icon src={calendar} />
      <StyledDatePicker
        selected={value}
        onChange={handleDateChange}
        shouldCloseOnSelect={shouldCloseOnSelect}
        isClearable={false}
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        placeholderText={placeholderText}
        customInput={customInput}
        dateFormat={dateFormat}
        calendarClassName="calendar-className"
        disabled={disabled}
        {...props}
      />
    </Div>
  );
};

export default DatePickerComponent;
