import React from "react";
import PropTypes from "prop-types";
import { StyledButton } from "./Button.styles";

const Button = ({ children, variant, type, width, height, ...restProps }) => {
  return (
    <StyledButton
      $variant={variant}
      $type={type}
      $width={width}
      $height={height}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(["default", "outlined", "link", "footerButton"]),
  type: PropTypes.oneOf(["primary", "secondary", "submit"]),
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

Button.defaultProps = {
  variant: "default",
  type: "primary",
  width: undefined,
  height: undefined,
};

export default Button;
