import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addRecipients,
  getRecipients,
  editRecipient,
} from "features/recipients/recipientsActions";
import { selectRecipients } from "features/recipients/recipientsSlice";
import Input from "components/input";
import ButtonPersonal from "../components/buttonPersonal";
import { Col, RowContainer, HalfScr, BtnsWrapper, ZipWrapper } from "./Recipients.styles";

const AddRecipients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(selectRecipients);
  const { id } = useParams();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");

  useEffect(() => {
    dispatch(getRecipients());
  }, [dispatch]);

  useEffect(() => {
    const recipient = data.find((recipient) => recipient.id === Number(id));

    if (recipient) {
      setName(recipient.name || "");
      setSurname(recipient.surname || "");
      setCountry(recipient.country || "");
      setCity(recipient.city || "");
      setState(recipient.state || "");
      setAddress(recipient.address || "");
      setPhoneNumber(recipient.phone_number || "");
      setZipCode(recipient.zip_code || "");
    }
  }, [data, id]);

  const handleSave = () => {
    const recipientData = {
      id,
      name,
      surname,
      country,
      city,
      state,
      address,
      phone_number: phoneNumber,
      zip_code: zipCode,
    };

    if (!id) {
      dispatch(addRecipients(recipientData));
    } else {
      dispatch(editRecipient(recipientData));
    }

    navigate("/recipients");
  };

  const handleClickCancelAdd = () => {
    navigate("/recipients");
  };

  return (
    <Col>
      <RowContainer>
        <Input label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <Input label="Surname" value={surname} onChange={(e) => setSurname(e.target.value)} />
        <Input
          label="Phone number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </RowContainer>
      <RowContainer>
        <Input label="Country" value={country} onChange={(e) => setCountry(e.target.value)} />
        <Input label="City" value={city} onChange={(e) => setCity(e.target.value)} />
        <Input label="State" value={state} onChange={(e) => setState(e.target.value)} />
      </RowContainer>
      <HalfScr>
        <Input label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
      </HalfScr>
      <ZipWrapper>
        <Input label="Zip code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
      </ZipWrapper>
      <BtnsWrapper>
        <ButtonPersonal type="cancel" width={208} onClick={handleClickCancelAdd}>
          Cancel
        </ButtonPersonal>
        <ButtonPersonal type="submit" width={208} onClick={handleSave}>
          Save
        </ButtonPersonal>
      </BtnsWrapper>
    </Col>
  );
};

export default AddRecipients;
