import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCalcModalOpen: false,
};

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    openCalculatorModal: (state) => {
      state.isCalcModalOpen = true;
    },
    closeCalculatorModal: (state) => {
      state.isCalcModalOpen = false;
    },
  },
});

export const { openCalculatorModal, closeCalculatorModal } = calculatorSlice.actions;

export const selectIsCalcModalOpen = (state) => state.calculator.isCalcModalOpen;

export default calculatorSlice.reducer;
