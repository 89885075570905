import React, { useState } from "react";
import { addOrders } from "features/orders/ordersActions";
import Input from "components/input";
import { Select } from "components/select";
import Checkbox from "components/checkbox";
import plane from "./../assets/plane.png";
import ship from "./../assets/ship.png";
import usa from "./../assets/usa.png";
import china from "./../assets/china.png";
import germany from "./../assets/germany.png";
import uk from "./../assets/uk.png";
import {
  CountryWrapper,
  DeliveryMethod,
  Plane,
  Title,
  Ship,
  Days,
  Row,
  RowContainer,
  CountryLabel,
  InputWrapper,
  Column,
  Label,
  LabelWrapepr,
  AreaWrapper,
  Country,
  Actions,
} from "./AddOrders.styles";
import ButtonPersonal from "../../components/buttonPersonal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tooltip from "../../../../components/tooltip/Tooltip";

const AddOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [declarationName, setDeclarationName] = useState("");
  const [declarationPrice, setDeclarationPrice] = useState("");
  const [declarationCurrency, setDeclarationCurrency] = useState("");
  const [total, setTotal] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCheckboxChange = (value) => {
    if (value === "ship") {
      setDeliveryMethod("Ship");
    } else if (value === "plane") {
      setDeliveryMethod("Plane");
    }
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  const currencyOptions = [
    { value: "$", label: "$" },
    { value: "€", label: "€" },
  ];

  const handleInputChange = (field, value) => {
    switch (field) {
      case "trackingNumber":
        setTrackingNumber(value);
        break;
      case "declarationName":
        setDeclarationName(value);
        break;
      case "declarationPrice":
        setDeclarationPrice(value);
        break;
      case "declarationCurrency":
        setDeclarationCurrency(value);
        break;
      case "total":
        setTotal(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const formData = {
      name: declarationName,
      shipped_method: deliveryMethod,
      tracking_number: trackingNumber,
      total: total,
      price: declarationPrice,
      currency: declarationCurrency.value,
      country: selectedCountry,
    };

    dispatch(addOrders(formData));
    navigate("/orders");
  };

  const handleCancel = () => {
    navigate("/orders");
  };

  return (
    <>
      <RowContainer>
        <Column>
          <CountryWrapper>
            <CountryLabel>
              <Label>Delivery</Label>
              <Label>country</Label>
            </CountryLabel>
            <AreaWrapper>
              <Country src={usa} />
              <p>USA</p>
              <Checkbox size={"large"} onChange={() => handleCountryChange("USA")} />
            </AreaWrapper>
            <AreaWrapper>
              <Country src={china} />
              <p>China</p>
              <Checkbox size={"large"} onChange={() => handleCountryChange("China")} />
            </AreaWrapper>
            <AreaWrapper>
              <Country src={germany} />
              <p>Germany</p>
              <Checkbox size={"large"} onChange={() => handleCountryChange("Germany")} />
            </AreaWrapper>
            <AreaWrapper>
              <Country src={uk} />
              <p>UK</p>
              <Checkbox size={"large"} onChange={() => handleCountryChange("UK")} />
            </AreaWrapper>
          </CountryWrapper>
          <InputWrapper>
            <LabelWrapepr>
              <Label>Tracking number</Label>
            </LabelWrapepr>
            <Input
              noBordered
              placeholder="Tracking number"
              height="59px"
              onChange={(e) => handleInputChange("trackingNumber", e.target.value)}
            />
          </InputWrapper>
          <Tooltip text="Please, enter the tracking number and price of the product in order to avoid visiting the Customs House">
            <InputWrapper>
              <Label>Declaration</Label>
              <Input
                noBordered
                height="45px"
                placeholder="Name"
                onChange={(e) => handleInputChange("declarationName", e.target.value)}
              />
              <Input
                noBordered
                height="45px"
                placeholder="Price"
                onChange={(e) => handleInputChange("declarationPrice", e.target.value)}
              />
              <Select
                placeholder="$"
                onChange={(value) => handleInputChange("declarationCurrency", value)}
                options={currencyOptions}
              />
              <Label>Total</Label>
              <Input
                noBordered
                height="45px"
                placeholder="0.00"
                onChange={(e) => handleInputChange("total", e.target.value)}
              />
            </InputWrapper>
          </Tooltip>
        </Column>
        <DeliveryMethod>
          <Title>Delivery method</Title>
          <Row>
            <div>
              <Ship src={ship} alt="ship" />
              <Days>60-85 days</Days>
            </div>
            <Checkbox size={"large"} onChange={() => handleCheckboxChange("ship")} />
          </Row>
          <Row>
            <div>
              <Plane src={plane} alt="plane" />
              <Days>7-10 days</Days>
            </div>
            <Checkbox size={"large"} onChange={() => handleCheckboxChange("plane")} />
          </Row>
        </DeliveryMethod>
      </RowContainer>
      <Actions>
        <ButtonPersonal type={"cancel"} onClick={handleCancel}>
          Cancel
        </ButtonPersonal>

        <ButtonPersonal type={"submit"} onClick={handleSubmit}>
          ADD PARCEL
        </ButtonPersonal>
      </Actions>
    </>
  );
};

export default AddOrders;
