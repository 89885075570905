import { useDispatch } from "react-redux";
import { setIsLoginPage } from "features/auth/authSlice";
import apple from "./../icons/apple.png";
import google from "./../icons/google.png";
import { Icon, Row, StyledP, StyledSignInBtn } from "../login/Login.styles";
import { Image, RowContainer, ColumnContainer } from "./RegStart.styles";

const RegStart = () => {
  const dispatch = useDispatch();

  const handleClickRegisterWithMail = () => {
    dispatch(setIsLoginPage("signUp"));
  };

  return (
    <RowContainer>
      <Image>
        <h1>Sign up</h1>
      </Image>
      <ColumnContainer>
        <StyledSignInBtn>
          <Icon src={google} alt="google" />
          Sign in with Google
        </StyledSignInBtn>
        <StyledSignInBtn>
          <Icon src={apple} alt="apple" />
          Sign in with Apple
        </StyledSignInBtn>
        <Row>
          <hr />
          <StyledP>or sign in with e-mail</StyledP>
          <hr />
        </Row>
        <StyledSignInBtn onClick={handleClickRegisterWithMail}>
          Continue with e-mail
        </StyledSignInBtn>
      </ColumnContainer>
    </RowContainer>
  );
};

export default RegStart;
