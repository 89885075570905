import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AccordionContainer, AccordionHeader, AccordionContent } from "./Accordion.styles";

const Accordion = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <AccordionContainer>
      {data?.map((item, index) => (
        <div key={index}>
          <AccordionHeader
            onClick={() => toggleAccordion(index)}
            $isOpen={openIndex === index}
          >
            {openIndex === index ? (
              <KeyboardArrowUpIcon fontSize="large" />
            ) : (
              <KeyboardArrowDownIcon fontSize="large" />
            )}
            {item.question}
          </AccordionHeader>
          <AccordionContent $isOpen={openIndex === index}>
            <p>{item.answer}</p>
          </AccordionContent>
        </div>
      ))}
    </AccordionContainer>
  );
};

export default Accordion;
