import styled from "styled-components";

export const Container = styled.div`
  background: #e8eded;
  border-radius: 999999px;
  width: 120px;
  height: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-size: 45px;
  color: #58b5e7;

  position: absolute;
  right: -85px;
  top: -9px;
  z-index: 111;
`;

export const UploadInput = styled.input`
  display: none;
`;
