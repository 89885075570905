import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../api/axiosClient";

export const getTransportation = createAsyncThunk(
  "transportation",
  async (_, { rejectWithValue }) => {
    try {
      const data = await ApiClient.get("/v1/shop_ways");
      return data.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getWhatDoing = createAsyncThunk("what/doing", async (_, { rejectWithValue }) => {
  try {
    const data = await ApiClient.get("/v1/what/doing");
    return data.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getDelivery = createAsyncThunk("delivery", async (_, { rejectWithValue }) => {
  try {
    const data = await ApiClient.get("/v1/deliver");
    return data.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getTeam = createAsyncThunk("team", async (_, { rejectWithValue }) => {
  try {
    const data = await ApiClient.get("/v1/team");
    return data.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getFooterMeta = createAsyncThunk(
  "footer/meta",
  async (_, { rejectWithValue }) => {
    try {
      const data = await ApiClient.get("/v1/footer/meta");
      return data.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
