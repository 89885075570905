import styled, { css } from "styled-components";
import { theme } from "styles/Theme";

const { spacing, typography, colors, borderRadius } = theme;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ labelLeft }) =>
    labelLeft &&
    css`
      flex-direction: row;
      align-items: center;
      gap: 10px;

      ${InputLabel} {
        color: #4e4e53;
        opacity: 1;
        font: ${typography.fontSize.formLabel} ${typography.fontFamily.body};
        line-height: ${typography.lineHeight.small};
        // width: 25%;
      }
    `};
`;

export const InputFieldWithIcons = styled.div`
  width: 100%;
  position: relative;
`;

export const InputField = styled.input`
  width: 100%;
  height: ${({ $customHeight }) => ($customHeight ? $customHeight : "60px")};
  position: relative;
  padding: ${spacing.small};
  padding-left: 29px;
  border: ${({ $isValid, $noBordered, $secondaryBorder }) => {
    if (!$isValid) {
      return `2px solid #EA4335`;
    } else if ($noBordered) {
      return "none";
    } else if ($secondaryBorder) {
      return "1px solid #DBDBDB";
    } else {
      return `2px solid #262757`;
    }
  }};
  border-radius: ${borderRadius.large};
  background-color: ${colors.white};
  outline: none;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;

  color: #262757;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;

  &::placeholder {
    color: #262757;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 141.667% */
  }

  &:hover,
  &:focus {
    border-color: ${({ $isValid }) => ($isValid ? "#B7C54C" : colors.primary)};
    box-shadow: ${({ $isValid }) =>
      $isValid
        ? "0px 0px 5px 0px rgba(183, 197, 76, 0.3)"
        : "0px 0px 5px 0px rgba(0, 119, 255, 0.3)"};
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ $isValid }) => ($isValid ? "#B7C54C" : colors.primary)};
  }

  &:invalid {
    border-color: #ea4335;
    box-shadow: 0px 0px 5px 0px rgba(234, 67, 53, 0.3);
  }

  ${({ type }) =>
    type === "number" &&
    css`
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield; /* Firefox */

        /* Chrome, Safari, Edge, Opera */
        appearance: textfield;
      }
    `}

  ${({ type }) =>
    type === "password" &&
    css`
      /* Styles for password input */
      &::-ms-reveal {
        display: none;
      }

      &::-ms-clear {
        display: none;
      }
    `}

  ${({ prefix, suffix }) =>
    (prefix || suffix) &&
    css`
      padding-left: ${spacing.medium};
      padding-right: calc(${spacing.medium} + 25px);
    `}

  ${({ prefix }) =>
    prefix &&
    css`
      padding-right: ${spacing.small};
    `}

  ${({ suffix, $deleteIcon }) =>
    suffix &&
    $deleteIcon &&
    css`
      padding-left: ${spacing.small};
      padding-right: calc(${spacing.medium} + 35px);
    `}
`;

export const InputLabel = styled.label`
  color: #262757;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 34.5px; /* 172.5% */
`;

export const InputPrefix = styled.div`
  color: ${colors.inputPlaceholder};

  position: absolute;
  display: flex;
  top: 50%;
  left: ${spacing.medium};
  transform: translateY(-50%);
`;

export const InputSuffix = styled.div`
  color: ${colors.inputPlaceholder};

  position: absolute;
  display: flex;
  top: 50%;
  right: ${spacing.medium};
  transform: translateY(-50%);
  z-index: 1;
`;

export const DeleteButton = styled.button`
  position: absolute;
  padding: 2px;
  top: 50%;
  right: ${spacing.small};
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;

  ${({ withSuffix }) =>
    withSuffix &&
    css`
      right: 1.8rem;
    `};
`;

export const DisabledMessage = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: red;
`;

export const HighlightedDiv = styled.div`
  width: 100%;
  position: relative;
  padding: ${spacing.small};
  border: 1px solid ${colors.gray};
  border-radius: ${borderRadius.medium};
  font: ${typography.fontSize.medium} ${typography.fontFamily.body};
  font-weight: ${typography.fontWeight.regular};
  line-height: ${typography.lineHeight.small};
  color: ${colors.text};
  background-color: ${colors.white};
  outline: none;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.p`
  color: red;
`;
